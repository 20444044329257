import React, { useEffect, useState } from "react";
import InputBox from "components/common/InputBox";
import { Form, Row } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import api from "api";
import InputDropdown from "components/common/InputDropdown";
const Attributes = ({
  defaultProps,
  modifyBuilding,
  mdCol,
  area = true,
  attributes = true,
  setBuildigDetail = () => {},
}) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const getAttributes = async () => {
    const res = await api.get("/datasetting-buildings");

    console.log(res, "data hai");
    setData(res.data);
  };

  useEffect(() => {
    getAttributes();
  }, []);

  return (
    <Row>
      {area == true && (
        <div>
          <h5
            style={{
              textAlign: "center",
              fontWeight: "700",
              margin: "1.5rem 0rem",
            }}
          >
            {t("property_page.Areas")}
          </h5>
          <InputBox
            {...defaultProps}
            mdCol={mdCol}
            text={"Area BTA"}
            id={"area_bta"}
            value={modifyBuilding?.area_bta}
          />
          <InputBox
            {...defaultProps}
            mdCol={mdCol}
            text={"Area BRA"}
            id={"area_bra"}
            value={modifyBuilding?.area_bra}
          />
          <InputBox
            {...defaultProps}
            mdCol={mdCol}
            text={"Area BOA"}
            id={"area_boa"}
            value={modifyBuilding?.area_boa}
          />
          <InputBox
            {...defaultProps}
            mdCol={mdCol}
            text={"Area LOA"}
            id={"area_loa"}
            value={modifyBuilding?.area_loa}
          />
          <InputBox
            {...defaultProps}
            mdCol={mdCol}
            text={"Area A-temp"}
            id={"area_a_temp"}
            value={modifyBuilding?.area_a_temp}
          />
        </div>
      )}
      {attributes == true && (
        <div>
          <h5
            style={{
              textAlign: "center",
              fontWeight: "700",
              margin: "1.5rem 0rem",
            }}
          >
            {t("property_page.Attributes")}
          </h5>
          {/* <InputBox
            {...defaultProps}
            mdCol={mdCol}
            text={t("property_page.facade")}
            id={"u_facade"}
            value={modifyBuilding?.u_facade}
          /> */}

          <InputDropdown
            {...defaultProps}
            mdCol={mdCol}
            value={modifyBuilding?.u_facade}
            options={data?.find((el) => el?.attribute_type == "facade")?.value}
            id="u_facade"
            text={t("common.pages.Attribute Facade")}
          />

          <InputDropdown
            {...defaultProps}
            mdCol={mdCol}
            value={modifyBuilding?.u_windows}
            options={data?.find((el) => el?.attribute_type == "windows")?.value}
            id="u_windows"
            text={t("common.pages.Attribute Windows")}
          />

          <InputDropdown
            {...defaultProps}
            mdCol={mdCol}
            value={modifyBuilding?.u_doors}
            options={data?.find((el) => el?.attribute_type == "doors")?.value}
            id="u_doors"
            text={t("common.pages.Attribute Doors")}
          />

          <InputDropdown
            {...defaultProps}
            mdCol={mdCol}
            value={modifyBuilding?.u_roof}
            options={data?.find((el) => el?.attribute_type == "roof")?.value}
            id="u_roof"
            text={t("common.pages.Attribute Roof")}
          />

          <InputDropdown
            {...defaultProps}
            mdCol={mdCol}
            value={modifyBuilding?.foundation}
            options={
              data?.find((el) => el?.attribute_type == "foundation")?.value
            }
            id="foundation"
            text={t("common.pages.Attribute Foundation")}
          />

          <InputDropdown
            {...defaultProps}
            mdCol={mdCol}
            value={modifyBuilding?.electricity}
            options={
              data?.find((el) => el?.attribute_type == "electricity")?.value
            }
            id="electricity"
            text={t("common.pages.Attribute Electricity")}
          />

          <InputDropdown
            {...defaultProps}
            mdCol={mdCol}
            value={modifyBuilding?.heating}
            options={data?.find((el) => el?.attribute_type == "heating")?.value}
            id="heating"
            text={t("common.pages.Attribute Heating")}
          />

          <InputDropdown
            {...defaultProps}
            mdCol={mdCol}
            value={modifyBuilding?.heat_distribution}
            options={
              data?.find((el) => el?.attribute_type == "heat_distribution")
                ?.value
            }
            id="heat_distribution"
            text={t("common.pages.Attribute Heat Distribution")}
          />

          <InputDropdown
            {...defaultProps}
            mdCol={mdCol}
            value={modifyBuilding?.ventilation}
            options={
              data?.find((el) => el?.attribute_type == "ventilation")?.value
            }
            id="ventilation"
            text={t("common.pages.Attribute Ventilation")}
          />
        </div>
      )}
    </Row>
  );
};
export default Attributes;
