import useAsync from "./useAsync.js";

function getToken() {
  if (localStorage.getItem("token")) {
    const token = JSON.parse(localStorage.getItem("token") || "");
    return token;
  }
  return "";
}

const DEFAULT_OPTIONS = {
  headers: {
    "Content-Type": "application/json111",
    Authorization: `Bearer ${getToken()}`,
  },
};

export default function useFetch(url, options = {}, dependencies = []) {
  return useAsync(async () => {
    const res = await fetch(url, { ...DEFAULT_OPTIONS, ...options });
    if (res?.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location = "/sign-in";
      return;
    } else {
      if (res.ok) return res.json();
      const json = await res.json();
      return await Promise.reject(json);
    }
  }, dependencies);
}
