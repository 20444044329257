import React, { useEffect, useState } from "react";
import { SidePanelRoot, SidePanelService } from "components/common/SidePanel";
import myProfileSidePanel from "./myProfileSidePanel";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import { Dropdown } from "@themesberg/react-bootstrap";
import "./myprofile.css";
import api from "api";
import UserDeleteModal from "./UserDeleteModal";
import { t } from "i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DropdownComponent from "components/common/Dropdown";

const Myprofile = ({
  newItem,
  editItem,
  deleteItem,
  handleChangeAction,
  selectedUser,
  setSelectedUser,
  users,
  setUsers,
  setCurrentTab,
  setUser,
  dupUsers,
  admin,
}) => {
  const [showMaxUserModal, setShowMaxUserModal] = useState(false);
  const [maxUserMessage, setMaxUserMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const history = useHistory();
  // const [users, setUsers] = useState([]);

  // Delete Modal State
  const [show, setShow] = useState(false);

  const handleCloseMaxUser = () => {
    handleChangeAction(null);
    setShowMaxUserModal(false);
  };

  useEffect(() => {
    setUsers(dupUsers);
    if (admin) {
      setSelectedUser(admin);
    }
  }, []);

  const handleUpgradePlan = async () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    let res = await api.get(`/stripe/getCustomerPortal/${user?._id}`);
    setLoading(false);
    window.location = res?.data;
  };
  const handleSubmit = async (e, data) => {
    setDisabledBtn(true);
    e.preventDefault();
    data.isVerified = true;
    if (newItem) {
      // data.profileUser = true;
      const user = JSON.parse(localStorage.getItem("user"));
      data.tenantId = user?._id;
      data.role = "user";
      const res = await api.post("/auth/signup", data);
      debugger;
      if (res?.response?.data?.message) {
        setMaxUserMessage(res?.response?.data?.message);
        return setShowMaxUserModal(true);
      }
      setUsers([...users, res.data]);
      // setUser(res.data);
      setSelectedUser(res?.data);
      handlePopulateValue(res?.data);
      setCurrentTab("settings");
    } else {
      if (data?.password) {
        data.passwordChange = true;
      } else {
        data.password = selectedUser?.password;
        data.passwordChange = false;
      }
      let res = await api.patch(`/users/edit/${selectedUser?._id}`, data);

      setUsers(
        users.map((elem) => {
          if (elem._id == res.data._id) {
            return (elem = res.data);
          } else {
            return elem;
          }
        })
      );
      window.location.reload();
    }
    handleChangeAction(null);
    history.push("/useraccounts");
    setDisabledBtn(false);
  };

  const handleNewProperty = (item) => {
    if (selectedUser || newItem) {
      let d = editItem ? { ...selectedUser, password: null } : null;
      SidePanelService.open(myProfileSidePanel, {
        handleSubmit,
        initalVal: d,
        // newTask,
        disabledBtn,
        handleClose: () => {
          handleChangeAction(null);
          history.push("/useraccounts");
        },
      });
    } else {
      return toast("Please Select the User!", { type: "error" });
    }
  };

  useEffect(() => {
    if (newItem) {
      handleNewProperty();
    } else if (editItem) {
      handleNewProperty();
    } else if (deleteItem) {
      setShow(true);
    }
  }, [newItem, editItem, deleteItem]);

  // const getAllUser = async () => {
  //   try {
  //     const user = JSON.parse(localStorage.getItem("user"));
  //     let allprofileUser = await api.get(`/users/adminId/${user?._id}`);
  //     setUsers(allprofileUser?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getAllUser();
  // }, []);

  const handlePopulateValue = (elem) => {
    setSelectedUser(elem);
  };

  // Delete Modal Function
  const deleteModalClose = () => {
    setShow(false);
    handleChangeAction(null);
  };

  return (
    <div>
      {(newItem || editItem) && <SidePanelRoot />}
      <Container>
        <Row>
          <Col xl={4} lg={7}>
            <div className="activity-input-container mt-5">
              <Dropdown className="dropdown_year">
                <Dropdown.Toggle className="activites_year_dropdown activites_dropdown my_profile_dropdown my_profile_toogle">
                  {t("common.pages.select_user")}
                  <span class="material-symbols-outlined">arrow_drop_down</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="my_profile_dropdown dropDown_scroll">
                  {users?.map((elem) => {
                    return (
                      <Dropdown.Item
                        onClick={() => handlePopulateValue(elem)}
                        className="activitesYear_dropdown_menu_item"
                      >
                        {elem?.email}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>

              <Form.Group>
                <Form.Label>{t("common.pages.Email")}</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  value={selectedUser?.email}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("common.pages.Password")}</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  value={selectedUser?.password ? "passw" : null}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("property_page.name")}</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  value={selectedUser?.name}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("data_settings.phone")} </Form.Label>
                <Form.Control
                  name="mobile_phone"
                  type="number"
                  value={selectedUser?.mobile_phone}
                  disabled
                />
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Delete Modal */}
      {show && (
        <UserDeleteModal
          deleteModalClose={deleteModalClose}
          show={show}
          users={users}
          populateData={selectedUser}
          setUsers={setUsers}
        />
      )}
      {/* Maximum User Modal  */}
      <Modal
        show={showMaxUserModal}
        onHide={handleCloseMaxUser}
        centered
        className="email_verification_modal_main"
      >
        <Modal.Header closeButton>
          <Modal.Title> {t("common.pages.chg_plan")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("common.pages.you_can_add_maximum") + " "}
          {maxUserMessage ? maxUserMessage - 1 : 0}
          {t("property_page.user")}.
          {" " + t("property_page.if_you_add_more_users")}
          <div className="update_btn_main">
            <Button
              variant="primary"
              onClick={handleUpgradePlan}
              className="update_btn_change_plan mt-2"
              disabled={loading}
            >
              {loading ? "Loading..." : t("common.pages.update_plan")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Myprofile;
