import React, { useEffect, useState } from "react";
import InputBox from "components/common/InputBox";
import { Col, Form, Row } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import api from "api";
import { usePropertyContextCheck } from "context/SidebarContext/PropertyContextCheck";

const MainData = ({
  defaultProps,
  newTask,
  modifyBuilding,
  mdCol,
  sidePanel,
  setModifyBuilding,
  onboarding,
}) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const { propertyChange } = usePropertyContextCheck();
  const getAllUser = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      let allprofileUser = await api.get(`/users/adminId/${user?._id}`);
      setUsers(allprofileUser?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUser();
  }, []);

  return (
    <Row>
      <InputBox
        {...defaultProps}
        mdCol={mdCol}
        text={t("property_page.property_number")}
        disabled={true}
        id={"property_code"}
        value={onboarding ? modifyBuilding?.property_code : propertyChange}
        // className="drawer_property_number"
      />
      <InputBox
        {...defaultProps}
        mdCol={mdCol}
        disabled={newTask ? false : true}
        required={true}
        text={t("property_page.building_code")}
        id={"building_code"}
        value={modifyBuilding?.building_code}
      />
      <InputBox
        {...defaultProps}
        mdCol={mdCol}
        text={t("property_page.building_name")}
        id={"name"}
        required={true}
        value={modifyBuilding?.name}
      />
      <InputBox
        {...defaultProps}
        mdCol={mdCol}
        text={t("property_page.address")}
        id={"street_address"}
        value={modifyBuilding?.street_address}
      />
      <InputBox
        {...defaultProps}
        mdCol={mdCol}
        text={t("property_page.zip_code")}
        id={"zip_code"}
        value={modifyBuilding?.zip_code}
      />
      <InputBox
        {...defaultProps}
        mdCol={mdCol}
        text={t("property_page.city")}
        id={"city"}
        value={modifyBuilding?.city}
      />
      <InputBox
        {...defaultProps}
        mdCol={mdCol}
        text={t("property_page.longitude")}
        id={"longitude"}
        value={modifyBuilding?.longitude}
      />
      <InputBox
        {...defaultProps}
        mdCol={mdCol}
        text={t("property_page.latitude")}
        id={"latitude"}
        value={modifyBuilding?.latitude}
      />
      {/* <InputBox
      {...defaultProps}
      mdCol={mdCol}
      text={t("property_page.geo_fence")}
      id={"geo_fence"}
      value={modifyBuilding?.geo_fence}
    /> */}
      {/* <InputBox
      {...defaultProps}
      mdCol={mdCol}
      text={t("property_page.responsible_user")}
      id={"responsible_user"}
      value={modifyBuilding?.responsible_user}
    /> */}
      <Col md={sidePanel ? 12 : 6}>
        <Form.Group className={sidePanel ? "responsible" : null}>
          <Form.Label className="maindata_resposible resposible_user">
            {t("property_page.responsible_user")}
          </Form.Label>
          <Form.Select
            value={modifyBuilding?.responsible_user || ""}
            id={"responsible_user"}
            onChange={(e) => {
              defaultProps.handleChange({
                target: { name: "responsible_user", value: e.target.value },
              });
            }}
            disabled={sidePanel ? false : true}
            className="main_data_form_select"
          >
            <option value={""} disabled>
              -
            </option>

            {users?.map((elem) => {
              return (
                <option value={elem?.email?.toUpperCase()}>
                  {elem?.email}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Col>
      <InputBox
        {...defaultProps}
        mdCol={mdCol}
        text={t("property_page.construction_year")}
        id={"construction_year"}
        value={modifyBuilding?.construction_year}
      />
    </Row>
  );
};

export default MainData;
