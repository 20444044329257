import { FilterReportesPerType, GetFilters } from "lib/PlanningLib";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "components/common/Loader";
import MultiSelectDropdown from "components/common/MultiSelectDropdown";
import MultiRangeSlider from "components/common/MultiRangeSlider";
import { Dropdown, Table } from "@themesberg/react-bootstrap";
import "../ActivitesYear/activitesYear.css";
import Filter from "components/common/Filter";

import api from "api";
import leaf_icon from "../../../../../../assets/img/report/🦆 icon _leaf.png";
import money_icon from "../../../../../../assets/img/report/🦆 icon _money.png";
import risk_icon from "../../../../../../assets/img/report/🦆 icon _risk major.png";
import project_icon from "../../../../../../assets/img/report/🦆 icon _project.png";
import search_icon from "../../../../../../assets/img/report/🦆 icon _search.png";
import { FaCaretDown } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { SidePanelRoot, SidePanelService } from "components/common/SidePanel";
import filesSidePanel from "../ActivitesYear/filesSidePanel";
import { toast } from "react-toastify";
import ActivitesYearSidePanel from "../ActivitesYear/activitesYearSidePanel";
import DeleteModal from "../ActivitesYear/DeleteModal";
import DetailModal from "../ActivitesYear/detailModal";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintModal from "../ActivitesYear/PrintModal";
import { usePlanningContextCheck } from "context/SidebarContext/PlanningContextCheck";
import {
  depOptions,
  getAllMaintenanceDiagramData,
  getMaintenanceDepositionData,
  getMaintenanceReport,
  getMaintenanceSettings,
  options,
  sortContent,
  uniquePropertyAndBuildings,
} from "utils/MaintenanceReport";
import PrintData from "../ActivitesYear/PrintData";
import { GetAllProperties } from "lib/PropertiesLib";
import Switch from "../../../../../common/Switch";

const ActivitesType = ({
  printItem,
  createReport,
  handleChangeAction,
  currReprtTab,
}) => {
  // Filter State
  const { value } = GetAllProperties(undefined, undefined, true);
  const [showFilters, setShowFilters] = useState(false);
  const printRef = useRef();
  const [filterValues, setFilterValues] = useState({});
  const { value: allFilters } = GetFilters();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showMenuCol, setShowMenuCol] = useState(true);
  const [printModal, setPrintModal] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [calculation, setCalculation] = useState(true);

  // Filter Code
  const handleFilterClick = () => {
    setShowFilters(!showFilters);
  };

  // activites year State
  const [maintainancePlan, setMaintainancePlan] = useState([]);
  const [dupMaintainancePlan, setDupMaintainancePlan] = useState([]);

  const [breakIndexs, setBreakIndexs] = useState([]);
  const [printData, setPrintData] = useState(null);
  const [dupPrintData, setDupPrintData] = useState(null);

  // Side Panel State
  const [initalVal, setInitalVal] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [formData, setFormData] = useState(null);

  // Detail Modal State
  const [detailModal, setDetailModal] = useState(false);

  const [selectedPoints, setSelectedPoints] = useState([]);
  const [depositionData, setDepositionData] = useState(null);
  const [uniquePropsAndBuilds, setUniquePropsAndBuilds] = useState(null);
  const [maintenanceReport, setMaintenanceReport] = useState(null);
  const [maintenanceSettings, setMaintenanceSettings] = useState(null);
  const [pageNumbering, setPageNumbering] = useState({});
  const [maintananceDiagramData, setMaintananceDiagramData] = useState({
    labels: [],
    datasets: [],
  });
  const { actvsPerYearBreakIndexs, actvsPerYearPrintData } =
    usePlanningContextCheck();

  const { setActvsPerTypePrintData, setActvsPerTypeBreakIndexs } =
    usePlanningContextCheck();

  // Delete Modal State
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);

  const handleDeleteFilter = (valueToDelete) => {
    Object.keys(filterValues).forEach((key) => {
      if (Array.isArray(filterValues[key])) {
        const index = filterValues[key].indexOf(valueToDelete);
        if (index !== -1) {
          setFilterValues((prevObject) => ({
            ...prevObject,
            [key]: prevObject[key].filter((item, i) => i !== index),
          }));
        }
      }
    });
  };

  const handleFindClick = async () => {
    // perform find logic using filterValues

    let filterObj = {};
    for (const key in filterValues) {
      if (filterValues[key]?.length > 0) {
        filterObj[key] = filterValues[key];
      }
    }
    const res = await FilterReportesPerType({
      body: JSON.stringify({
        filters: filterObj,
      }),
    });
    const newData = await res.json();
    setMaintainancePlan(newData);
  };

  const filterAnimation = {
    maxHeight: showFilters ? "500px" : "0",
    opacity: showFilters ? "1" : "0",
    visibility: showFilters ? "visible" : "hidden",
    transition: "max-height 0.5s ease-out, opacity 0.5s ease-out",
  };

  const handleFilterChange = (name, value) => {
    setFilterValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  function splitArray(array, chunkSize, elem) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push({ ...elem, documents: array.slice(i, i + chunkSize) });
    }
    return result;
  }

  const getAllMaintenancePlan = async (spinner) => {
    if (spinner === false) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    try {
      let allMaintenancePlan = await api.post(
        "/planning_component/maintainance/activitesPertype"
      );
      let allData = JSON.parse(JSON.stringify(allMaintenancePlan));
      let indexs = [];
      let updatedData = [];
      let a = 0;

      for (let i = 0; i < allData?.data?.length; i++) {
        if (a == 9) {
          updatedData.push(allData?.data[i]);
          indexs.push(i);
          a = allData?.data[i]?.documents?.length;
        } else if (a + allData?.data[i]?.documents?.length > 9) {
          let remaining = 9 - a;
          let remElem = allData?.data[i]?.documents.splice(0, remaining);
          let splitArrays;
          if (allData?.data[i]?.documents?.length > 9) {
            splitArrays = splitArray(
              allData?.data[i]?.documents,
              9,
              allData?.data[i]
            );
          } else {
            splitArrays = [allData?.data[i]];
          }
          updatedData.push(
            {
              ...allData?.data[i],
              documents: remElem,
            },
            // allData?.data[i]
            ...splitArrays
          );

          indexs.push(updatedData?.length - 1);
          a = updatedData[updatedData?.length - 1]?.documents?.length;
          // indexs.push(i + 1);
          // a = allData?.data[i + 1]?.documents?.length;
          // a = 0;
        } else {
          a += allData?.data[i]?.documents?.length;
          updatedData.push(allData?.data[i]);
        }
      }

      // let indexs = [];
      // let a = 0;
      // allMaintenancePlan?.data?.map((el, i) => {
      //   if (a + el?.documents?.length > 9) {
      //     indexs.push(i);
      //     a = 0;
      //   } else {
      //     a += el?.documents?.length;
      //   }
      // });
      setBreakIndexs(indexs);
      setPrintData(updatedData);
      setDupPrintData(updatedData);
      setMaintainancePlan(allMaintenancePlan.data);
      // setting data in useContext
      setActvsPerTypePrintData(updatedData);
      setActvsPerTypeBreakIndexs(indexs);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let u = JSON.parse(localStorage.getItem("user"));
    setUser(u);
    getAllMaintenancePlan();
  }, [showDrawer]);

  useEffect(() => {
    let u = JSON.parse(localStorage.getItem("user"));
    if (u) getMaintencanceSettings(u?._id);
    getAllMaintenanceDiagramData(setMaintananceDiagramData);
    getMaintenanceDepositionData(setDepositionData);
    getMaintenanceReport(setMaintenanceReport);
    getMaintenanceSettings(setMaintenanceSettings);
  }, []);

  useEffect(() => {
    sortContent(
      selectedPoints,
      uniquePropsAndBuilds,
      breakIndexs,
      actvsPerYearBreakIndexs,
      setPageNumbering
    );
  }, [selectedPoints]);

  // useEffect(() => {
  //   uniquePropertyAndBuildings(
  //     maintainancePlan,
  //     value,
  //     setUniquePropsAndBuilds
  //   );
  // }, [value, maintainancePlan]);

  const data = {
    labels: depositionData?.depositions?.map((elem) => {
      return elem.deposition_year;
    }),
    datasets: [
      {
        label: t("data_settings.rec_deposition"),
        data: depositionData?.depositions?.map((elem) => {
          return elem.rec_value_fund;
        }),
        backgroundColor: "lightYellow",
        borderColor: "#FF9A25",
        borderWidth: 2,
      },
      {
        label: t("data_settings.current_deposition"),
        data: depositionData?.depositions?.map((elem) => {
          return elem.curr_value_fund;
        }),
        borderColor: "#413F41",
        backgroundColor: "navy",
        borderWidth: 2,
      },
    ],
  };

  const handleChangeStatus = async (StatusName, documentId) => {
    try {
      let res = await api.patch(
        `/planning_component/maintainance/activites-year-status/${documentId}`,
        { StatusName }
      );
      handleFindClick();
    } catch (error) {
      console.log(error);
    }
  };

  const getMaintencanceSettings = async (id) => {
    const res = await api.get(`/maintenance_settings/${id}`);
    setFormData(res?.data);
  };

  // Detail Modal Functions
  const detailModalClose = () => setDetailModal(false);
  const detailModalShow = (item) => {
    setInitalVal(item);
    setDetailModal(true);
  };

  const filesModal = (item) => {
    setTimeout(() => {
      setInitalVal(item);
      SidePanelService.open(filesSidePanel, {
        handleSubmit,
        initalVal: item,
        handleClose: () => {
          setShowDrawer(false);
        },
      });
    }, 100);
  };

  const handleSubmit = async (e, data, isCopyItems) => {
    try {
      e.preventDefault();
      if (isCopyItems) {
        data._id = undefined;
        const res = await api.post(
          "/planning_component/maintainance/activitesPerYear-copy",
          data
        );
      } else {
        let res = await api.patch(
          `/planning_component/maintainance/activitesPerYear-edit/${data._id}`,
          data
        );
        let responseItem = maintainancePlan?.map((elem) => {
          if (elem._id == res.data._id) {
            return (elem = res.data);
          } else {
            return elem;
          }
        });
        setMaintainancePlan(responseItem);
      }
      setShowDrawer(false);
    } catch (error) {
      console.log(error);
      toast("Some Error", { type: "error" });
    }
  };

  // Edit Copy Modal
  const handleNewProperty = (item, isCopyItems) => {
    setInitalVal(item);
    setTimeout(() => {
      SidePanelService.open(ActivitesYearSidePanel, {
        handleSubmit,
        initalValue: item,
        isCopyItems,
        handleClose: () => {
          setShowDrawer(false);
        },
      });
    }, 100);
  };

  const handleShow = (item) => {
    setInitalVal(item);
    setShow(true);
  };

  // Delete Modal Function
  const deleteModalClose = () => {
    setInitalVal(null);
    setShow(false);
  };

  const genratePdf = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Activities Per Type",
    onAfterPrint: () => {
      setShowMenuCol(true);
    },
  });
  const handlePrintClick = () => {
    setShowMenuCol(false);
    setTimeout(() => {
      genratePdf();
      handleChangeAction(null);
    }, [100]);
  };

  const handleChangePoint = (point) => {
    if (selectedPoints?.includes(point)) {
      setSelectedPoints(selectedPoints?.filter((el) => el !== point));
    } else {
      setSelectedPoints([...selectedPoints, point]);
    }
  };

  const handleOpenModal = () => {
    setPrintModal(true);
  };

  const handleClosePrintModal = () => {
    setSelectedPoints([]);
    handleChangeAction(null);
    setPrintModal(false);
  };
  useEffect(() => {
    if (printItem && currReprtTab == "activitestype") {
      handleChangePoint("maintenanceActivitiesPerSystem");
      handlePrintClick();
    }
  }, [printItem]);

  useEffect(() => {
    if (createReport && currReprtTab === "activitestype") {
      handleOpenModal();
    }
  }, [createReport]);
  // if (!allFilters) return <Loader />;

  const calculatePercentage = () => {
    let percent = formData?.general_surcharge;
    if (percent) {
      let updatedPlan = maintainancePlan?.map((elem) => {
        return {
          ...elem,
          totalCost: (elem?.totalCost * percent) / 100 + elem?.totalCost,
          documents: elem.documents.map((item) => {
            if (item?.total_cost) {
              return {
                ...item,
                total_cost:
                  (parseInt(item?.total_cost) * percent) / 100 +
                  parseInt(item?.total_cost),
              };
            } else {
              return item;
            }
          }),
        };
      });
      // let yearlyIncrease = 0;
      updatedPlan = updatedPlan?.map((elem) => {
        if (
          elem?._id > formData?.base_year_increase &&
          formData?.yearly_increase
        ) {
          let total_cost = elem?.totalCost;
          let factor = formData?.yearly_increase / 100 + 1;
          let differenece = elem?._id - formData?.base_year_increase;

          let result = Math.pow(factor, differenece);
          console.log(elem, "elem");
          console.log(result, "result");
          // yearlyIncrease = yearlyIncrease + formData?.yearly_increase;
          return {
            ...elem,
            totalCost: result * total_cost,
            documents: elem.documents.map((item) => {
              if (item?.total_cost) {
                total_cost = item?.total_cost;
                factor = formData?.yearly_increase / 100 + 1;
                differenece = elem?._id - formData?.base_year_increase;
                result = Math.pow(factor, differenece);
                return {
                  ...item,
                  total_cost: result * total_cost,
                };
              } else {
                return item;
              }
            }),
          };
        } else {
          return elem;
        }
      });
      setMaintainancePlan(updatedPlan);
      setDupMaintainancePlan(updatedPlan);
    }
  };

  const addVAT = (data, percent) => {
    let updatedPlan = data?.map((elem) => {
      return {
        ...elem,
        totalCost: (elem?.totalCost * percent) / 100 + elem?.totalCost,
        documents: elem.documents.map((item) => {
          if (item?.total_cost) {
            return {
              ...item,
              total_cost:
                (parseInt(item?.total_cost) * percent) / 100 +
                parseInt(item?.total_cost),
            };
          } else {
            return item;
          }
        }),
      };
    });
    return updatedPlan;
  };

  useEffect(() => {
    if (formData && maintainancePlan.length > 0 && calculation) {
      calculatePercentage();
      setCalculation(false);
    }
  }, [formData, maintainancePlan]);

  const handleChangeSwitch = (e) => {
    setSwitchState(e);
    if (e === true && formData?.vat_percent) {
      let percent = formData.vat_percent;

      let updatedPlan = addVAT(maintainancePlan, percent);
      let updatedPrintData = addVAT(printData, percent);
      setMaintainancePlan(updatedPlan);
      setPrintData(updatedPrintData);
      setActvsPerTypePrintData(updatedPrintData);
    } else {
      setMaintainancePlan(dupMaintainancePlan);
      setActvsPerTypePrintData(dupPrintData);
      setPrintData(dupPrintData);
    }
  };

  return (
    <>
      {/* Filter Code */}
      <Filter
        handleFindClick={handleFindClick}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        status={true}
      />
      <div className="side_paneL_root_main">
        {showDrawer && <SidePanelRoot />}
      </div>
      {loading ? (
        <div style={{ marginBottom: "1rem" }}>
          <Loader />
        </div>
      ) : (
        <div className="table_scroll">
          <Table>
            <thead>
              <tr className="activites_header">
                <th>{t("planning_page.activity")}</th>
                <th>{t("planning_page.article")}</th>
                <th> {t("planning_page.Year")} </th>
                <th>{t("planning_page.interval")}</th>
                <th className="flags_header">{t("planning_page.flags")}</th>
                <th>STATUS</th>
                <th>
                  <div className="vat_switch">
                    <Switch
                      checked={switchState}
                      setChecked={setSwitchState}
                      onChange={handleChangeSwitch}
                      text={
                        !switchState
                          ? t("common.pages.EX. VAT")
                          : t("common.pages.INC. VAT")
                      }
                    />
                  </div>
                  <div>{t("planning_page.total_cost")}</div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="activites_year_table_main">
              {maintainancePlan?.map((elem) => (
                <>
                  <tr className="activites_start_year activites_year_cost_main">
                    <td> {`${elem._id} ${elem.uSystemName}`} </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {`${elem?.totalCost}`?.length <= 4
                        ? elem.totalCost
                        : elem.totalCost?.toLocaleString()}
                    </td>
                    <td></td>
                  </tr>
                  {elem?.documents?.map((item) => (
                    <tr className="activites_start_year activites_activity">
                      <td className="reportYearTD activiCol">
                        <div style={{ textWrap: "wrap" }}>
                          {item.maintenance_activity?.length <= 73
                            ? item.maintenance_activity
                            : `${item.maintenance_activity.substring(
                                0,
                                73
                              )}...`}
                          {/* {item.maintenance_activity} */}
                        </div>
                      </td>
                      <td className={"reportYearTD"}>{item.article}</td>
                      <td className={"reportYearTD"}>{item.start_year}</td>
                      <td className={"reportYearTD"}>
                        {item.technical_life + " " + t("planning_page.years")}{" "}
                      </td>
                      <td className={"reportYearTD flags_row"}>
                        {item.energy_flag && (
                          <img
                            src={leaf_icon}
                            alt="leaf-icon"
                            className={"leaf_img"}
                          />
                        )}
                        {item.invest_flag && (
                          <img
                            src={money_icon}
                            alt="money-icon"
                            className={"leaf_img"}
                          />
                        )}
                        {item.risk_flag && (
                          <img
                            src={risk_icon}
                            alt="risk-icon"
                            className={"leaf_img"}
                          />
                        )}
                        {item.project_flag && (
                          <img
                            src={project_icon}
                            alt="project-icon"
                            className={"leaf_img"}
                          />
                        )}

                        {item.inspection_flag && (
                          <img
                            src={search_icon}
                            alt="search-icon"
                            className={"leaf_img"}
                          />
                        )}
                      </td>
                      <td className="reportYearTD">
                        <Dropdown className={"dropdown_year"}>
                          <Dropdown.Toggle className="activites_year_dropdown activtesYear_dropdown_btn activites_dropdown">
                            <div className="status_color_main">
                              {item?.status === "Planerad" ? (
                                <div className="plan_color_div dropdown_icon plan_color"></div>
                              ) : item?.status === "Akut" ? (
                                <div className="plan_color_div dropdown_icon akut_color"></div>
                              ) : item?.status === "Eftersatt" ? (
                                <div className="plan_color_div dropdown_icon efter_color"></div>
                              ) : item?.status === "Beslutad" ? (
                                <div className="plan_color_div dropdown_icon beslu_color"></div>
                              ) : item?.status === "Utförd" ? (
                                <div className="plan_color_div dropdown_icon utford_color"></div>
                              ) : null}
                              {!item.status || item.status === "Choose"
                                ? t("common.pages.choose")
                                : item.status}
                            </div>
                            <FaCaretDown />
                          </Dropdown.Toggle>
                          <div className="dropdown_menu_main">
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Planerad", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon plan_color"></div>
                                Planerad
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Akut", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon akut_color"></div>
                                Akut
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Eftersatt", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon efter_color"></div>
                                Eftersatt
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Beslutad", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon beslu_color"></div>
                                Beslutad
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Utförd", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon utford_color"></div>
                                Utförd
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </div>
                        </Dropdown>
                      </td>
                      <td className={"reportYearTD"}>
                        {`${item.total_cost}`?.length <= 4
                          ? item.total_cost
                          : item.total_cost?.toLocaleString()}
                      </td>
                      <td>
                        <Dropdown className="dropdown_year" drop={"left"}>
                          <Dropdown.Toggle className="activites_year_dropdown">
                            <BsThreeDots />
                          </Dropdown.Toggle>
                          <div className="dropdown_menu_main">
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => detailModalShow(item)}
                                className="Year_edit_menu_item"
                              >
                                {t("planning_page.details")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setShowDrawer(true);
                                  filesModal(item);
                                }}
                                className="Year_edit_menu_item"
                              >
                                {t("planning_page.files")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setShowDrawer(true);
                                  handleNewProperty(item, true);
                                }}
                                className="Year_edit_menu_item"
                              >
                                {t("planning_page.copy")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="Year_edit_menu_item"
                                onClick={() => {
                                  setShowDrawer(true);
                                  handleNewProperty(item, false);
                                }}
                              >
                                {t("planning_page.edit")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="Year_edit_menu_item"
                                onClick={() => handleShow(item)}
                              >
                                {t("planning_page.delete")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </div>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {/* Table For Prinintg ... */}
      <div
        ref={printRef}
        className={showMenuCol ? "displayNone" : ""}
        style={{ margin: "0px 45px 0px 45px" }}
      >
        <PrintData
          selectedPoints={selectedPoints}
          maintenanceReport={maintenanceReport}
          pageNumbering={pageNumbering}
          filterValues={filterValues}
          maintenanceSettings={maintenanceSettings}
          uniquePropsAndBuilds={uniquePropsAndBuilds}
          maintananceDiagramData={maintananceDiagramData}
          options={options}
          user={user}
          printData={actvsPerYearPrintData}
          breakIndexs={actvsPerYearBreakIndexs}
          actvsPerTypeBreakIndexs={breakIndexs}
          actvsPerTypePrintData={printData}
          depositionData={depositionData}
          depOptions={depOptions}
          allProperties={value}
        />
      </div>

      {/* Delete Modal */}
      {show && (
        <DeleteModal
          deleteModalClose={deleteModalClose}
          show={show}
          initalVal={initalVal}
          setMaintainancePlan={setMaintainancePlan}
          maintainancePlan={maintainancePlan}
          activitesType={"activitesType"}
        />
      )}

      {/* Detail Modal */}
      {detailModal && (
        <DetailModal
          detailModalClose={detailModalClose}
          detailModal={detailModal}
          initalVal={initalVal}
        />
      )}

      {/* Print Modal */}
      <PrintModal
        show={printModal}
        setPrintModal={setPrintModal}
        handleChangeAction={handleChangeAction}
        handleChangePoint={handleChangePoint}
        handlePrintClick={handlePrintClick}
        setSelectedPoints={setSelectedPoints}
        handleClosePrintModal={handleClosePrintModal}
      />
    </>
  );
};

export default ActivitesType;
