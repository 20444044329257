import React, { useEffect, useState } from "react";
import "./Analysis.css";
import api from "api";
import { Bar } from "react-chartjs-2";
import Loader from "components/common/Loader";
import { FilterAnalysis } from "lib/PlanningLib";
import Filter from "components/common/Filter";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { toast } from "react-toastify";
import { GetAllUSystems } from "lib/USystemsLib";
import Switch from "../../../../common/Switch";
import { t } from "i18next";

const Analysis = ({ printAnalysis, handleChangeAction }) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [dupData, setDupData] = useState({
    labels: [],
    datasets: [],
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [Usystems, setUsystems] = useState([]);
  const [switchState, setSwitchState] = useState(false);
  const [calculation, setCalculation] = useState(true);
  // Filter State
  const [filterValues, setFilterValues] = useState({});

  const printRef = useRef();

  const getAllMaintenancePlan = async () => {
    let a = new Date().getFullYear() + 100;
    let b = new Date().getFullYear() - 100;

    const user = JSON.parse(localStorage.getItem("user"));
    setLoading(true);
    try {
      let allMaintenancePlan = await api.post(
        `/planning_component/maintainance/analysis/${user?._id}`
      );

      setData({
        labels: allMaintenancePlan?.data?.labels,
        datasets: allMaintenancePlan?.data?.data,
      });
      setDupData({
        labels: allMaintenancePlan?.data?.labels,
        datasets: allMaintenancePlan?.data?.data,
      });

      console.log(allMaintenancePlan?.data, "data h bhai");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const calculatePercentage = async (allData) => {
    try {
      let labels = allData?.labels;
      let datasets = allData?.datasets;

      let percent = formData.general_surcharge;

      let updatedData = await Promise.all(
        datasets?.map((elem) => {
          return {
            ...elem,
            data: elem?.data?.map((item) => {
              return (item * percent) / 100 + item;
            }),
          };
        })
      );

      let base_year_increase = formData?.base_year_increase;
      // let yearly_increase = formData?.yearly_increase;

      let getIndex = labels?.findIndex((el) => el == base_year_increase);

      updatedData = await Promise.all(
        updatedData?.map((elem) => {
          return {
            ...elem,
            data: elem?.data?.map((item, index) => {
              if (index > getIndex) {
                let total_cost = elem?.totalCost;
                let factor = formData?.yearly_increase / 100 + 1;
                let differenece = elem?._id - formData?.base_year_increase;
                let result = Math.pow(factor, differenece);
                // yearly_increase = yearly_increase + formData?.yearly_increase;
                return result * total_cost;
              } else {
                return item;
              }
            }),
          };
        })
      );

      setData({
        labels: labels,
        datasets: updatedData,
      });
      setDupData({
        labels: labels,
        datasets: updatedData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData && data?.labels.length > 0 && calculation) {
      calculatePercentage(data);
      setCalculation(false);
    }
  }, [formData, data]);

  const getUsystems = async () => {
    setLoading(true);
    try {
      let all_u_systems = await api.get(`/u_systems?analysis=true`);
      setUsystems(all_u_systems?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      getMaintencanceSettings(user?._id);
    }
    getAllMaintenancePlan();
    getUsystems();
  }, []);

  useEffect(() => {
    if (printAnalysis) {
      handlePrintClick();
    }
  }, [printAnalysis]);

  const genratePdf = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Analysis",
  });

  const handlePrintClick = () => {
    setTimeout(() => {
      genratePdf();
      handleChangeAction(null);
    }, [100]);
  };

  const getMaintencanceSettings = async (id) => {
    const res = await api.get(`/maintenance_settings/${id}`);
    setFormData(res?.data);
  };

  const plugins = {
    legend: {
      // display: false,
      // position: "bottom",
      // onClick: (e, item) => {
      //   console.log("Click Legend", e, item);
      // },

      onHover: (e, item) => {
        let txt = Usystems?.find(
          (el) => el?.system_code == item?.text
        )?.system_name;
        txt = txt ? txt : "No Text Provided!";
        toast(txt, {
          type: "info",
          autoClose: false,
          closeButton: false,
          // position: "top-center",
        });
      },
      onLeave: (e, item) => {
        toast.dismiss();
      },
    },
  };
  const options = {
    responsive: true,
    plugins: plugins,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const clickedElement = elements[0];
        const datasetIndex = clickedElement.datasetIndex;
        const dataIndex = clickedElement.index;
        const clickedValue = data.datasets[datasetIndex].data[dataIndex];

        let Year = data?.labels[dataIndex];
        let SystemCode = data?.datasets[datasetIndex]?.label;
        localStorage.setItem("activeTabIdPlanningMaintainance", "overview");
        window.open(`/maintainence?currentYear=${Year}&u_system=${SystemCode}`);
      } else {
        console.log("empty");
      }
    },
    // tooltips: {
    //   mode: "custom",
    //   intersect: false,
    //   callbacks: {
    //     title: (tooltipItem) => "umair", // Display "umair" in tooltip title
    //     label: (tooltipItem, data) => {
    //       const datasetIndex = tooltipItem.datasetIndex;
    //       console.log("datasetIndex", datasetIndex);
    //       const dataIndex = tooltipItem.index;
    //       const value = data.datasets[datasetIndex].data[dataIndex];
    //       return `Value: ${value}`;
    //     },
    //   },
    // },
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
        grid: {
          display: false, // Remove vertical grid lines
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
      },
    },
  };

  // Filter Code

  const handleFindClick = async () => {
    // perform find logic using filterValues

    let filterObj = {};
    for (const key in filterValues) {
      if (filterValues[key]?.length > 0) {
        filterObj[key] = filterValues[key];
      }
    }

    const res = await FilterAnalysis({
      body: JSON.stringify({
        filters: filterObj,
      }),
    });
    const newData = await res.json();
    if (filterObj?.start_year) {
      const [minYear, maxYear] = filterObj?.start_year[0]?.split("-");
      // Create an array to store the years
      const yearArray = [];

      // Use a loop to add years to the array
      for (let year = minYear; year <= maxYear; year++) {
        yearArray.push(year);
      }
      let mnYear = Number(minYear);
      let mxYear = Number(maxYear);
      if (
        newData?.labels.includes(mnYear) &&
        newData?.labels.includes(mxYear)
      ) {
        let filteredData = [];
        for (let i = mnYear; i <= mxYear; i++) {
          filteredData.push(newData?.data[newData?.labels.indexOf(i)]);
        }

        setData({
          labels: yearArray,
          datasets: filteredData,
        });
      } else {
        // toast("Out Of Range Please Change Maintenance Setting!", {
        //   type: "error",
        // });
      }
    } else {
      setData({
        labels: newData?.labels,
        datasets: newData?.data,
      });
    }
  };

  const handleChangeSwitch = (e) => {
    setSwitchState(e);
    if (e === true && formData?.vat_percent) {
      let percent = formData.vat_percent;
      let data_arr = data?.datasets;
      let updatedData = data_arr?.map((elem) => {
        return {
          ...elem,
          data: elem.data.map((item) => {
            return (item * percent) / 100 + item;
          }),
        };
      });
      setData({ ...data, datasets: updatedData });
    } else {
      setData(dupData);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Filter
        handleFindClick={handleFindClick}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        maintenanceAnalysis={true}
      />

      {/* .....................STacked Bar chart........................... */}
      <div
        ref={printRef}
        style={{
          padding: "0rem 1.5rem",
          width: "100%",
        }}
        className="analysis_main"
      >
        <div className="vat_switch">
          <Switch
            checked={switchState}
            setChecked={setSwitchState}
            onChange={handleChangeSwitch}
            text={
              !switchState
                ? t("common.pages.EX. VAT")
                : t("common.pages.INC. VAT")
            }
          />
        </div>
        <Bar data={data} options={options} />
      </div>
    </>
  );
};

export default Analysis;
