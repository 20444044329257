import { Form } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  SidePanel,
  SidePanelBody,
  SidePanelFooter,
  SidePanelHeader,
} from "components/common/SidePanel";
import MainData from "components/PropertyPage/BuildingDetails/components/MainData";
import Attributes from "components/PropertyPage/BuildingDetails/components/Attributes";
import Data1 from "components/PropertyPage/BuildingDetails/components/Quantities";
import Button from "components/common/Button";
import { useTranslation } from "react-i18next";

const BuuildingSidePanel = ({ handleSubmit, initalVal, handleClose }) => {
  const [buildigDetail, setBuildigDetail] = useState(initalVal);
  const { t } = useTranslation();

  const defaultProps = {
    required: false,
    handleChange: (e) => {
      setBuildigDetail((prev) => ({
        ...prev,
        [e.target.name]: e.target.value.toUpperCase(),
      }));
    },
  };

  useEffect(() => {
    console.log(buildigDetail, "detail");
  }, [buildigDetail]);

  return (
    <Form
      onSubmit={(e) => {
        handleSubmit(e, buildigDetail);
      }}
    >
      <SidePanel>
        <SidePanelHeader>{t("common.pages.Building Details")}</SidePanelHeader>
        <SidePanelBody>
          {buildigDetail?.target ? (
            <div className="activity-input-container">
              {buildigDetail?.target == "attributes" && (
                <Attributes
                  mdCol={12}
                  defaultProps={defaultProps}
                  modifyBuilding={buildigDetail}
                  setBuildigDetail={setBuildigDetail}
                  area={false}
                  attributes={true}
                />
              )}
              {buildigDetail?.target == "area" && (
                <Attributes
                  mdCol={12}
                  defaultProps={defaultProps}
                  modifyBuilding={buildigDetail}
                  area={true}
                  attributes={false}
                />
              )}
              {buildigDetail?.target == "quantity" && (
                <Data1
                  mdCol={12}
                  defaultProps={defaultProps}
                  modifyBuilding={buildigDetail}
                  imageUploader={true}
                  sidePanel={true}
                />
              )}
            </div>
          ) : (
            <div className="activity-input-container">
              <MainData
                mdCol={12}
                sidePanel={true}
                newTask={true}
                defaultProps={defaultProps}
                modifyBuilding={buildigDetail}
                onboarding={true}
              />
              <Attributes
                mdCol={12}
                defaultProps={defaultProps}
                modifyBuilding={buildigDetail}
              />
              <Data1
                mdCol={12}
                defaultProps={defaultProps}
                modifyBuilding={buildigDetail}
                imageUploader={true}
                sidePanel={true}
              />
            </div>
          )}
        </SidePanelBody>
        <SidePanelFooter>
          <Button main type="submit">
            {t("property_page.submit")}
          </Button>
          <Button
            secondary
            type="button"
            onClick={() => {
              handleClose();
            }}
          >
            {t("property_page.cancel")}
          </Button>
        </SidePanelFooter>
      </SidePanel>
    </Form>
  );
};

export default BuuildingSidePanel;

// export default BuuildingSidePanel;
