import { Row, Col, Nav, Tab } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";

export default function Tabs({
  tabValues,
  colLg,
  activeTabId,
  onTabChange,
  maintenanceTabWidth,
  settingMaintenance,
}) {
  const [activeTab, setActiveTab] = useState(activeTabId || tabValues[0].id);

  const setLocalStorage = (clickTab) => {
    localStorage.setItem("activeTabSettingMaintenance", clickTab);
  };
  useEffect(() => {
    if (activeTabId) {
      setActiveTab(activeTabId);
    }
  }, [activeTabId]);

  return (
    <Tab.Container
      defaultActiveKey={activeTabId || tabValues[0].id}
      onSelect={(id) => {
        setActiveTab(id);
        onTabChange && onTabChange(id);
      }}
    >
      <Row>
        <Col lg={colLg ? colLg : maintenanceTabWidth ? 12 : 11}>
          <Nav
            fill
            className="text-white flex-column flex-sm-row"
            style={{ gap: "3px" }}
          >
            {tabValues.map(
              ({ name, id, ifCon = true }, i) =>
                ifCon && (
                  <Nav.Item
                    key={i}
                    style={{ flex: colLg ? "0.1 0.1 auto" : "0 0 16%" }}
                    className="property_tabs"
                  >
                    <Nav.Link
                      eventKey={id}
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        border: "1px solid #2e3650",
                        borderBottom: "0px",
                        background: activeTab === id ? "#f5f8fb" : "#2e3650",
                        color: activeTab === id ? "#2e3650" : "#f5f8fb",
                      }}
                      className="py-3 mb-sm-3 mb-md-0 property_tabs"
                      onClick={
                        settingMaintenance
                          ? () => setLocalStorage(id)
                          : undefined
                      }
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                )
            )}
          </Nav>
          <Tab.Content>
            {tabValues.map(
              ({ ifCon = true, Component, props, id }, i) =>
                ifCon && (
                  <Tab.Pane
                    style={{ overflowX: "visible" }}
                    eventKey={id}
                    className="py-4"
                    key={id}
                  >
                    <Component {...props} />
                  </Tab.Pane>
                )
            )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}
