import React, { createContext, useContext, useEffect, useState } from "react";

const PropertyContextCheck = createContext();

export const usePropertyContextCheck = () => useContext(PropertyContextCheck);

const PropertyContextCheckProvider = ({ children }) => {
  const [propertyChange, setPropertyChange] = useState(undefined);
  const [property, setProperty] = useState(null);

  const [buildingChange, setBuildingChange] = useState(undefined);
  const [buildingObj, setBuildingObj] = useState(null);
  const [compObj, setCompObj] = useState(null);

  const [componentChange, setComponentChange] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [buildings, setBuildings] = useState(undefined);

  useEffect(() => {
    const previousProperty = localStorage.getItem("property");
    const propertyObj = JSON.parse(localStorage.getItem("propertyObj"));
    if (previousProperty) setPropertyChange(previousProperty);
    if (propertyObj) setProperty(propertyObj);
  }, []);

  useEffect(() => {
    const previousBuilding = localStorage.getItem("building");
    const buildingObj = JSON.parse(localStorage.getItem("buildingObj"));
    if (previousBuilding) setBuildingChange(previousBuilding);
    if (buildingObj) setBuildingObj(buildingObj);
  }, []);

  useEffect(() => {
    const previousComponent = localStorage.getItem("component");
    const compObj = JSON.parse(localStorage.getItem("compObj"));

    if (previousComponent) setComponentChange(previousComponent);
    if (compObj) setCompObj(compObj);
  }, []);

  return (
    <PropertyContextCheck.Provider
      value={{
        propertyChange,
        setPropertyChange,
        buildingChange,
        setBuildingChange,
        componentChange,
        setComponentChange,
        selectedUser,
        setSelectedUser,
        buildings,
        setBuildings,
        setProperty,
        property,
        setBuildingObj,
        buildingObj,
        setCompObj,
        compObj,
      }}
    >
      {children}
    </PropertyContextCheck.Provider>
  );
};

export default PropertyContextCheckProvider;
