import api from "api";
import Import from "pages/Import/Import";
import React from "react";

const PlanTable = ({
  setStep,
  setCsvFile,
  csvFile,
  properties,
  step,
  setStopStep,
}) => {
  const handleDataSubmit = async (data) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));

      data.isFirstLogin = true;
      let res = await api.post(
        `/onboarding/${user?.role == "user" ? user?.tenantId : user?._id}`,
        data
      );

      localStorage.setItem("user", JSON.stringify(res.data));
      setStopStep(null);
      setStep(5);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {csvFile && (
        <span
          class="material-symbols-outlined step_arrow_back"
          onClick={() => setStopStep("PlanUpload")}
        >
          arrow_back
        </span>
      )}
      <div className="maintenance_main">
        <p className="maintenance_plan_head">Adjust values where necessary</p>
        <Import
          setStep={setStep}
          step={step}
          setCsvFile={setCsvFile}
          csvFile={csvFile}
          propertiesData={properties}
          handleDataSubmit={handleDataSubmit}
          setStopStep={setStopStep}
        />
      </div>
    </>
  );
};

export default PlanTable;
