import React, { useEffect, useState } from "react";

import Tabs from "../components/common/Tabs";
import { usePropertyContextCheck } from "../context/SidebarContext/PropertyContextCheck";

import PropertyDetails from "../components/PropertyPage/PropertyDetails";
import BuildingDetails from "../components/PropertyPage/BuildingDetails";
import ComponentDetails from "../components/PropertyPage/ComponentDetails";
import Breadcrumbs from "../components/common/Breadcrumbs";
import Dropdown from "../components/common/Dropdown";
import { useTranslation } from "react-i18next";

const DefaultState = () => {
  const { t } = useTranslation();
  return <p>{t("property_page.Please_select_a_property")} </p>;
};

const Property = () => {
  const [currentAction, setCurrentAction] = useState();
  const [dropdownReset, setDropdownReset] = useState(false);
  const [ActiveComponent, setActiveComponent] = useState({
    Component: DefaultState,
    props: {},
  });
  const { t } = useTranslation();

  const {
    propertyChange,
    componentChange,
    buildingChange,
    setBuildingChange,
    setComponentChange,
    setPropertyChange,
    setProperty,
  } = usePropertyContextCheck();

  const modifyAction = currentAction === "modify" ? true : false;
  const newTask = currentAction === "new" ? true : false;
  const newPackage = currentAction === "add_package" ? true : false;
  const deleteAction = currentAction === "delete" ? true : false;
  const newActivity = currentAction === "add_activity" ? true : false;
  const modifyActivity = currentAction === "modify_activity" ? true : false;
  const deleteActivity = currentAction === "delete_activity" ? true : false;
  const newBuilding = currentAction === "Building" ? true : false;
  const newComponent = currentAction === "Component" ? true : false;

  const handleChangeAction = (item) => {
    setCurrentAction(item);
  };

  const getQueryParam = (name) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name);
  };
  const tabValue = getQueryParam("tab");
  const selectProperty = getQueryParam("selectProperty");
  const selectedBuilding = getQueryParam("selectBuilding");

  useEffect(() => {
    if (tabValue === "addNewProperty") {
      setTimeout(() => {
        handleChangeAction("new");
      }, 1000);
    }
    if (tabValue === "addNewBuilding") {
      setBuildingChange(" ");
      setPropertyChange(selectProperty);
      // setProperty(selectPropId);
      setTimeout(() => {
        handleChangeAction("new");
      }, 1000);
    }

    if (tabValue === "addNewComp") {
      setPropertyChange(selectProperty);
      setBuildingChange(selectedBuilding);
      setComponentChange(" ");
      setTimeout(() => {
        handleChangeAction("new");
      }, 1000);
    }
  }, [tabValue]);

  useEffect(() => {
    if (componentChange) {
      setActiveComponent({
        Component: ComponentDetails,
        props: {
          handleChangeAction,
          modifyAction,
          newTask: newTask || newComponent,
          deleteAction,
          newPackage,
          newActivity,
          modifyActivity,
          deleteActivity,
        },
      });
    } else if (buildingChange) {
      setActiveComponent({
        Component: BuildingDetails,
        props: {
          handleChangeAction,
          modifyAction,
          newTask: newTask || newBuilding,
          deleteAction,
          setBuildingChange,
        },
      });
    } else if (propertyChange || !propertyChange) {
      setActiveComponent({
        Component: PropertyDetails,
        props: {
          handleChangeAction,
          modifyAction,
          newTask,
          deleteAction,
        },
      });
    } else {
      setActiveComponent({
        Component: DefaultState,
      });
    }
  }, [buildingChange, componentChange, propertyChange, currentAction]);

  useEffect(() => {
    if (currentAction === null) setDropdownReset(true);
  }, [currentAction]);

  useEffect(() => {
    console.log(buildingChange);
    debugger;
  }, [buildingChange]);

  const tabValues = [
    {
      name: t("common.pages.main_data"),
      id: "main_data",
      Component: ActiveComponent.Component,
      props: {
        ...ActiveComponent.props,
        mainData: true,
      },
    },
    {
      name: t("common.pages.attributes"),
      id: "attributes",
      Component: ActiveComponent.Component,
      props: {
        ...ActiveComponent.props,
        attributes: true,
      },
    },
    propertyChange && {
      name: t("common.pages.quantities"),
      id: "quantities",
      Component: ActiveComponent.Component,
      props: {
        ...ActiveComponent.props,
        quantities: true,
      },
      ifCon:
        (!componentChange && buildingChange) ||
        (!propertyChange && buildingChange),
    },
    {
      name: t("common.pages.info"),
      id: "info",
      Component: ActiveComponent.Component,
      props: {
        ...ActiveComponent.props,
        info: true,
      },
      ifCon: componentChange || (propertyChange && !buildingChange),
    },
  ].filter(Boolean); // Remove null entries from the array

  const dropDownItems = [
    {
      id: "new",
      if:
        propertyChange || componentChange || buildingChange
          ? true
          : !propertyChange
          ? true
          : false,
      text: `${t("common.pages.new")} ${
        componentChange
          ? t("property_page.comp_action")
          : buildingChange
          ? t("property_page.building_action")
          : propertyChange
          ? t("property_page.property_action")
          : t("property_page.property_action")
      }`,
      icon: "add",
      handleClick: (value) => handleChangeAction(value),
    },
    {
      text: t("common.pages.add_package"),
      if: componentChange && componentChange !== undefined ? true : false,
      id: "add_package",
      icon: "add",
      handleClick: (value) => handleChangeAction(value),
    },
    {
      id: "modify",
      if: propertyChange || componentChange || buildingChange ? true : false,
      text: `${t("common.pages.modify")} ${
        componentChange
          ? t("property_page.comp_action")
          : buildingChange
          ? t("property_page.building_action")
          : propertyChange
          ? t("property_page.property_action")
          : ""
      }`,
      icon: "edit",
      handleClick: (value) => handleChangeAction(value),
    },
    {
      id: "delete",
      if: propertyChange || componentChange || buildingChange ? true : false,
      text: `${t("common.pages.delete")} ${
        componentChange
          ? t("property_page.comp_action")
          : buildingChange
          ? t("property_page.building_action")
          : propertyChange
          ? t("property_page.property_action")
          : ""
      }`,
      icon: "delete",
      handleClick: (value) => handleChangeAction(value),
    },
    {
      id: "add_activity",
      if: componentChange && componentChange !== undefined ? true : false,
      text: t("common.pages.add_activity"),
      icon: "add",
      handleClick: (value) => handleChangeAction(value),
    },
  ];

  useEffect(() => {
    const previousProperty = localStorage.getItem("property");
    const previousBuilding = localStorage.getItem("building");
    const previousComponent = localStorage.getItem("component");
    if (previousProperty) setPropertyChange(previousProperty);
    if (previousBuilding) setBuildingChange(previousBuilding);
    if (previousComponent) setComponentChange(previousComponent);
  }, []);

  const breadCrumbItems = [
    {
      if: true,
      value: propertyChange || t("property_page.Select a property"),
      handleClick: () => {
        setBuildingChange(null);
        setComponentChange(null);
      },
    },
    {
      if: buildingChange,
      value: buildingChange,
      handleClick: () => {
        setComponentChange(null);
      },
    },
    {
      if: componentChange,
      value:
        componentChange === "Component"
          ? t(`common.pages.${componentChange}`)
          : componentChange,
      handleClick: () => {},
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Breadcrumbs items={breadCrumbItems} />
        <Dropdown
          handleClick={handleChangeAction}
          name={t("common.pages.actions")}
          nameReset={dropdownReset}
          items={dropDownItems}
        />
      </div>
      <div className="my-4">
        <Tabs tabValues={tabValues} />
      </div>
      <div></div>
    </div>
  );
};

export default Property;
