import React from "react";
import "./style.css";
import Navbar from "./navbar";

const Index = () => {
  return (
    <>
      <Navbar />
      <div className="how-it-works">
        <div className="header">Videohandledningar</div>
        <div style={{ margin: "3rem 0rem" }}>
          <div className="viceo-row-label">Grundläggande användande</div>
          <div className="video-row">
            <div className="video-card">
              <div className="video"></div>
              <div className="title">Video 1</div>
              <div className="des">[Kort beskrivning av videons innehåll]</div>
            </div>
            <div className="video-card">
              <div className="video"></div>
              <div className="title">Video 2</div>
              <div className="des">[Kort beskrivning av videons innehåll]</div>
            </div>
            <div className="video-card">
              <div className="video"></div>
              <div className="title">Video 3</div>
              <div className="des">[Kort beskrivning av videons innehåll]</div>
            </div>
          </div>
        </div>
        <div style={{ margin: "5rem 0rem" }}>
          <div className="viceo-row-label">Grundläggande användande</div>
          <div className="video-row">
            <div className="video-card">
              <div className="video"></div>
              <div className="title">Video 1</div>
              <div className="des">[Kort beskrivning av videons innehåll]</div>
            </div>
            <div className="video-card">
              <div className="video"></div>
              <div className="title">Video 2</div>
              <div className="des">[Kort beskrivning av videons innehåll]</div>
            </div>
            <div className="video-card">
              <div className="video"></div>
              <div className="title">Video 3</div>
              <div className="des">[Kort beskrivning av videons innehåll]</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
