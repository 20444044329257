import React from "react";
import GoogleMapReact from "google-map-react";
import { useState } from "react";
import { useEffect } from "react";
import api from "api";
import { Form, Table } from "@themesberg/react-bootstrap";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import buildingImg from "../../../assets/img/pages/building.png";
import { t } from "i18next";
import Filter from "../Planning/PlanningFilter";
import Loader from "components/common/Loader";

let apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const FollowUp = () => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [allBuildings, setAllBuildings] = useState([]);
  const [dupBuildings, setDupBuildings] = useState([]);
  const [toggle, setToggle] = useState("week");
  const [checkedPins, setCheckedPins] = useState(["red", "yellow", "black"]);
  const [loading, setLoading] = useState(false);

  const [filterValues, setFilterValues] = useState({});

  const handleMarkerClick = (marker) => {
    console.log("Marker clicked:", marker);
    setSelectedMarker(marker);
  };

  const customStyles = [
    {
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  const handlePinChange = (e, pinColor) => {
    let cPins;
    let value = e.target.checked;
    if (value) {
      cPins = [...checkedPins, pinColor];
      setCheckedPins(cPins);
    } else {
      cPins = checkedPins?.filter((el) => el !== pinColor);
      setCheckedPins(cPins);
    }
    setAllBuildings(
      dupBuildings?.filter((el) => cPins.includes(el?.buildingStatus))
    );
  };

  const handleShowProperty = async (value, comp) => {
    try {
      localStorage.setItem("property", value?.property_code?.property_code);
      localStorage.setItem("building", value?.building_code);
      localStorage.setItem("component", comp?.component_code);
    } catch (error) {
      console.log(error);
    }
  };

  const getBuildings = async (isLoading) => {
    try {
      setLoading(isLoading);
      let buildings = await api.get(
        `/buildings/withComps/followup?toggle=${toggle}`
      );
      debugger;
      setAllBuildings(buildings?.data);
      setDupBuildings(buildings?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleToogle = () => {
    toggle === "week" ? setToggle("month") : setToggle("week");
  };

  useEffect(() => {
    getBuildings(true);
  }, [toggle]);

  // useEffect(() => {
  //   loadGoogleMapsApi(() => {
  //     const map = new window.google.maps.Map(document.getElementById("map"), {
  //       center: { lat: 67.8601759249178, lng: 20.226174445382792 },
  //       zoom: 13,
  //       styles: customStyles,
  //     });

  //     allBuildings?.map((location, index) => {
  //       new window.google.maps.Marker({
  //         position: new window.google.maps.LatLng(
  //           location?.latitude,
  //           location.longitude
  //         ),
  //         map: map,
  //         label: `${location?.components}`,
  //         // label: {
  //         //   text: "6",
  //         //   className: "custom-marker-label",
  //         // },
  //       });
  //     });
  //   });
  // }, [allBuildings]);
  // const mapStyles = [
  //   {
  //     featureType: "poi",
  //     elementType: "labels",
  //     stylers: [{ visibility: "off" }],
  //   },
  //   {
  //     featureType: "road",
  //     elementType: "labels",
  //     stylers: [{ visibility: "off" }],
  //   },
  //   {
  //     featureType: "transit",
  //     elementType: "labels",
  //     stylers: [{ visibility: "off" }],
  //   },
  // ];

  // Filter Code
  const handleFindClick = async () => {
    // perform find logic using filterValues
    // let filterObj = {};
    // for (const key in filterValues) {
    //   if (filterValues[key]?.length > 0) {
    //     filterObj[key] = filterValues[key];
    //   }
    // }
    // const res = await FilterSupervisionPlanning({
    //   body: JSON.stringify({
    //     filters: filterObj,
    //   }),
    // });
    // const newData = await res.json();
    // setComponents(newData);
  };

  const handleLatAndLng = (type) => {
    if (type === "lat") {
      return allBuildings?.length > 0
        ? Number(allBuildings[0]?.latitude)
        : 67.8601759249178;
    } else {
      return allBuildings?.length > 0
        ? Number(allBuildings[0]?.longitude)
        : 20.226174445382792;
    }
  };
  if (loading) return <Loader />;

  return dupBuildings?.length > 0 ? (
    <>
      <Filter
        handleFindClick={handleFindClick}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
      <div className="mapMain">
        <div className="mapLeft">
          <GoogleMapReact
            bootstrapURLKeys={{ key: apiKey }}
            // defaultCenter={{ lat: 67.8601759249178, lng: 20.226174445382792 }}
            defaultCenter={{
              lat: handleLatAndLng("lat"),
              lng: handleLatAndLng("lng"),
            }}
            defaultZoom={13}
            options={{ styles: customStyles }}
          >
            {allBuildings?.map((building, index) => (
              <Popup
                className="superVisionPopup"
                key={index}
                lat={building.latitude}
                lng={building.longitude}
                trigger={
                  <div
                    className={`pin c_${building?.buildingStatus}`}
                    onClick={() => handleMarkerClick(building)}
                    // number={index + 1}
                  >
                    <span>{building?.components?.length}</span>
                  </div>
                }
                position="right top"
                on="click"
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{ padding: "0px", border: "none" }}
                arrow={true}
              >
                <div className="superVisionPopup">
                  <div className="popup_header">
                    <div
                      className="buildingImg"
                      style={{ width: "8rem", height: "5rem" }}
                    >
                      {building?.image?.link ? (
                        <img
                          src={building?.image?.link}
                          alt="building Img"
                          style={{ height: "100%" }}
                        />
                      ) : (
                        <span class="material-symbols-outlined supervision_home_icon">
                          home
                        </span>
                      )}
                    </div>
                    <div className="buildingDetails">
                      <h6 className="popup-buildCode">
                        {building?.building_code}
                      </h6>
                      <h6 className="popup-buildName">{building?.name}</h6>
                    </div>
                  </div>

                  <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                  <Table borderless>
                    <tbody>
                      {building?.components?.map((comp) => {
                        return (
                          <tr>
                            <td>
                              <Link
                                to={"/property"}
                                onClick={() =>
                                  handleShowProperty(building, comp)
                                }
                                className="planning_component_code"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {comp?.component_code}
                              </Link>
                            </td>
                            <td className="popup_table_data">{comp?.name}</td>
                            <td className="popup_table_data">
                              {comp?.u_system}
                            </td>
                            <td>
                              <div
                                className={`compStatus c_${comp?.deviationStatus}`}
                              ></div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Popup>
            ))}
          </GoogleMapReact>
        </div>
        <div className="mapRight">
          <div className="mt-2 follow_up_map_pin">
            <div className="pinCheck">
              <input
                className="form-check-input pinCheckMap"
                type="checkbox"
                onChange={(e) => handlePinChange(e, "black")}
                checked={checkedPins.includes("black")}
              />
              <div className="pin c_black">
                <span>
                  {
                    dupBuildings?.filter((el) => el?.buildingStatus === "black")
                      ?.length
                  }
                </span>
              </div>
            </div>
            <div className="pinCheck">
              <input
                className="form-check-input pinCheckMap"
                type="checkbox"
                onChange={(e) => handlePinChange(e, "yellow")}
                checked={checkedPins.includes("yellow")}
              />
              <div className="pin c_yellow">
                <span>
                  {
                    dupBuildings?.filter(
                      (el) => el?.buildingStatus === "yellow"
                    )?.length
                  }
                </span>
              </div>
            </div>
            <div className="pinCheck">
              <input
                className="form-check-input pinCheckMap"
                type="checkbox"
                onChange={(e) => handlePinChange(e, "red")}
                checked={checkedPins.includes("red")}
              />
              <div className="pin c_red">
                <span>
                  {" "}
                  {
                    dupBuildings?.filter((el) => el?.buildingStatus === "red")
                      ?.length
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2 d-flex">
            <span
              style={{ marginRight: "0.5rem" }}
              className="planning_filter_select"
            >
              {t("property_page.Week")}
            </span>
            <Form.Check
              type="switch"
              id="custom-switch"
              onChange={(e) => handleToogle()}
              className="follow_month_check"
              checked={toggle === "month"}
            />
            <span className="planning_month_head">
              {t("property_page.Month")}
            </span>
          </div>
        </div>
        {/* <div id="map" style={{ height: "70vh", width: "70%" }}></div> */}
      </div>
    </>
  ) : (
    t("common.pages.You don't have any building with latitude & langitude!")
  );
};

export default FollowUp;
