import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./navbar";
import "./style.css";
const Links = () => {
  return (
    <div>
      <Navbar />
      <div className="link-main">
        <h3>Börja använda Janus</h3>
        <div className="all-links">
          <Link to={"/help-resources/how-it-works"}>Så här fungerar Janus</Link>
          <Link to={"/help-resources/property-register"}>
            Fastighetsregister
          </Link>
          <Link to={"/help-resources/maintenance-planning"}>
            Underhållsplanering
          </Link>
          <Link to={"/help-resources/super-vision"}>Rondering</Link>
          <Link to={"/help-resources/mobileapp"}>Mobilapp för rondering</Link>
          <Link to={"/help-resources/data-setting"}>Datainställningar</Link>
          <Link to={"/help-resources/user-accounts"}>Användarkonton</Link>
        </div>
      </div>
    </div>
  );
};

export default Links;
