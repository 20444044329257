import {
  Col,
  Container,
  Form,
  FormCheck,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import "./ImagesFiles.css";
import { MdOutlineContentCopy } from "react-icons/md";
import Filter from "components/common/Filter";
import { t } from "i18next";
import api from "api";
import { toast } from "react-toastify";
import DeleteModal from "components/PlanningPage/MaintainancePage/components/Report/ActivitesYear/DeleteModal";
import Loader from "components/common/Loader";

const ImagesFiles = ({ handleChangeAction, deletefiles, setCurrentAction }) => {
  const [previewItem, setPreviewItem] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [filterValues, setFilterValues] = useState({});

  const [imagesFiles, setImagesFiles] = useState(null);
  const [dupImagesFiles, setDupImagesFiles] = useState(null);
  const [show, setShow] = useState(false);
  const [initalVal, setInitalVal] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleSelectPreview = (elem) => {
    setPreviewItem(elem);
  };

  useEffect(() => {
    if (deletefiles) {
      if (previewItem && previewItem !== "") {
        handleShow(previewItem);
      } else {
        toast(t("planning_page.please_select_at_least_one_item_to_perform"), {
          type: "error",
        });
        handleChangeAction(null);
      }
    }
  }, [deletefiles]);

  // Filter Code
  const handleFindClick = async () => {
    let updatedData;
    if (
      filterValues?.belongsTo?.length === 0 &&
      filterValues?.properties?.length === 0
    ) {
      return setImagesFiles(dupImagesFiles);
    }

    if (filterValues?.belongsTo && filterValues?.belongsTo?.length > 0) {
      updatedData = dupImagesFiles?.filter((el) =>
        filterValues?.belongsTo?.includes(el?.type)
      );
    }
    // ......
    else if (!filterValues?.properties) {
      updatedData = dupImagesFiles;
    }
    if (filterValues?.properties && filterValues?.properties?.length > 0) {
      let isProperties = updatedData?.find((el) =>
        filterValues?.properties?.includes(el?.name)
      );
      if (!isProperties) {
        let filteredProperties = dupImagesFiles?.filter(
          (item) =>
            item?.type === "Property" &&
            filterValues?.properties?.includes(item?.name)
        );
        if (updatedData?.length > 0) {
          updatedData = [...filteredProperties, ...updatedData];
        } else {
          updatedData = [...filteredProperties];
        }
      }
    }
    let { startDate, endDate } = filterValues;
    if (startDate && endDate) {
      if (startDate !== "" && endDate !== "") {
        updatedData = updatedData?.filter((el) => {
          if (
            new Date(el?.image?.createdAt).setHours(0, 0, 0, 0) >=
              new Date(startDate).setHours(0, 0, 0, 0) &&
            new Date(el?.image?.createdAt).setHours(0, 0, 0, 0) <=
              new Date(endDate).setHours(0, 0, 0, 0)
          ) {
            return el;
          }
        });
      }
    }

    setImagesFiles(updatedData);
  };

  const getImagesFiles = async () => {
    try {
      setLoading(true);
      let res = await api.get("/images-files");
      let properties = res?.data?.properties?.map((el) => {
        return {
          ...el,
          type: "Property",
        };
      });
      let buildings = res?.data?.buildings?.map((el) => {
        return {
          ...el,
          type: "Building",
        };
      });
      let components = res?.data?.components?.map((el) => {
        return {
          ...el,
          type: "Component",
        };
      });
      let maintenancePlans = res?.data?.maintenancePlans?.map((el) => {
        return {
          ...el,
          type: "Maintenance Plan",
        };
      });
      let data = [
        ...properties,
        ...buildings,
        ...components,
        ...maintenancePlans,
      ];
      setImagesFiles(data);
      setDupImagesFiles(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getImagesFiles();
  }, []);

  const handleShow = (item) => {
    setInitalVal(item);
    setShow(true);
  };

  // Delete Modal Function
  const deleteModalClose = () => {
    setCurrentAction();
    setInitalVal(null);
    setShow(false);
  };

  const deleteFiles = async (item) => {
    if (item.type === "Property") {
      await api.patch(`/images-files/${item?._id}`, { type: "Property" });
    } else if (item.type === "Building") {
      await api.patch(`/images-files/${item?._id}`, { type: "Building" });
    } else if (item.type === "Component") {
      await api.patch(`/images-files/${item?._id}`, { type: "Component" });
    } else if (item.type === "Maintenance Plan") {
      await api.patch(`/images-files/${item?._id}`, {
        type: "Maintenance Plan",
      });
    }

    window.location.reload();
    // let data = imagesFiles?.filter((elem) => {
    //   return elem._id !== item?._id;
    // });

    // setImagesFiles(data);
    // setDupImagesFiles(data);
    // setPreviewItem(null);
    // deleteModalClose();
  };

  return loading ? (
    <>
      {" "}
      <Loader />{" "}
    </>
  ) : (
    <div>
      <Row>
        <Col className="mt-5">
          <Filter
            handleFindClick={handleFindClick}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            ImagesFiles={true}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={8}>
          <div className="Images_files_table_main">
            <Table bordered hover>
              <th className="Images_header_name">
                {t("common.pages.Display Name")}
              </th>
              <th className="Images_header_name">
                {t("common.pages.Property")}
              </th>
              <th className="Images_header_name">
                {t("common.pages.Belongs to")}
              </th>
              <th className="Images_header_name"> Format </th>
              <th className="Images_header_name"> {t("common.pages.Size")} </th>
              <th className="Images_header_name">
                {t("common.pages.Created")}
              </th>

              <tbody className="Images_table_body_main">
                {imagesFiles?.map((elem, index) => {
                  return (
                    <tr key={elem?._id}>
                      <td className="Images_main">
                        <div className="display_name_main">
                          <FormCheck.Input
                            type="checkbox"
                            checked={previewItem?._id === elem?._id}
                            onChange={() => handleSelectPreview(elem)}
                            className="images_checkbox"
                          />
                          <img
                            src={elem.image?.link || elem.files?.[0]?.image}
                            alt="property"
                            className="property_image"
                          />
                          <a
                            href={elem.image?.link || elem.files?.[0]?.image}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="property_number">
                              16598{index}{" "}
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="Image_files_property images_files_property_code">
                        {elem?.component_code ||
                          elem?.start_year ||
                          elem?.building_code ||
                          elem?.property_code}
                        <span> {elem?.name || elem?.article}</span>
                      </td>
                      <td className="Image_files_property">{elem?.type}</td>
                      <td className="Image_files_property">
                        {(
                          elem?.image || elem?.files?.[0]
                        )?.format?.toUpperCase()}
                      </td>
                      <td className="Image_files_property">
                        {/* {elem?.dimensions} */}
                        1080 x 1325
                      </td>
                      <td className="Image_files_property">
                        {new Date(
                          (elem.image || elem.files?.[0])?.createdAt
                        ).toLocaleDateString()}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col lg={4}>
          <div className="preview_main">
            <p className="preview_heading">{t("common.pages.Preview")}</p>
            <div
              className={
                previewItem
                  ? "preview_div"
                  : "preview_div image_preview_default_line"
              }
            >
              {previewItem ? (
                <>
                  <div className="preview_property_image_main">
                    <img
                      src={
                        previewItem?.image?.link || previewItem?.files[0]?.image
                      }
                      alt="property_image"
                      className="preview_property_image"
                    />
                  </div>
                  <div className="preview_image_data_main">
                    <p className="created_date_main">
                      <span className="property_created_heading">
                        Created date
                      </span>
                      <span className="property_created_date">
                        {" "}
                        {new Date(
                          (
                            previewItem?.image || previewItem?.files[0]
                          )?.createdAt
                        ).toLocaleDateString()}
                      </span>
                    </p>
                    <p className="created_date_main">
                      <span className="property_created_heading">Url</span>
                      <span className="property_created_date">
                        <div className="d-flex">
                          <input
                            value={
                              previewItem?.image?.link ||
                              previewItem?.files[0]?.image
                            }
                          />
                          <div className="copy_icon_main">
                            <MdOutlineContentCopy className="copy_icon" />
                          </div>
                        </div>
                      </span>
                    </p>
                    <p className="created_date_main">
                      <span className="property_created_heading">
                        Access control
                      </span>
                      <div className="private_public_access_main">
                        <Form.Check
                          label="Private"
                          type="radio"
                          name="radioGroup"
                          id="radioOption1"
                          value="option1"
                          checked={selectedRadio === "option1"}
                          onChange={(e) => setSelectedRadio(e.target.value)}
                          className="access_radio_button"
                        />
                        <Form.Check
                          label="Public"
                          type="radio"
                          name="radioGroup"
                          id="radioOption2"
                          value="option2"
                          checked={selectedRadio === "option2"}
                          onChange={(e) => setSelectedRadio(e.target.value)}
                          className="access_radio_button"
                        />
                      </div>
                    </p>
                  </div>
                </>
              ) : (
                <span className="images_preview_default_line">
                  {" "}
                  Select an item in the grid to preview it
                </span>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {/* Delete Modal */}
      {show && (
        <DeleteModal
          deleteModalClose={deleteModalClose}
          show={show}
          modalBody={t("Are you sure you want to delete this Images Files?")}
          modalHeader={t("Delete Images Files")}
          deleteFunction={deleteFiles}
          deleteItemId={initalVal}
        />
      )}
    </div>
  );
};

export default ImagesFiles;
