export const Routes = {
  // pages
  LandingPage: { path: "/" },
  HelpResources: { path: "/help-resources" },
  HelpResourcesArticles: { path: "/help-resources-articles" },
  HowItWorks: { path: "/help-resources/how-it-works" },
  propertyRegister: { path: "/help-resources/property-register" },
  maintenancePlanning: { path: "/help-resources/maintenance-planning" },
  superVision: { path: "/help-resources/super-vision" },
  mobileApp: { path: "/help-resources/mobileapp" },
  dataSetting: { path: "/help-resources/data-setting" },
  userAccounts: { path: "/help-resources/user-accounts" },
  videoHandler: { path: "/help-resources/video-handler" },
  janusInterface: { path: "/help-resources/janus-interface" },
  DashboardOverview: { path: "/app" },
  Signin: { path: "/sign-in" },
  Pricing: { path: "/pricing-plan" },
  Signup: { path: "/sign-up" },
  NotFound: { path: "/404" },
  Property: { path: "/property" },
  DataSetting: { path: "/datasetting/maintenance" },
  UserAccounts: { path: "/useraccounts" },
  Maintainence: { path: "/maintainence" },
  Profile: { path: "/profile" },
  Supervision: { path: "/supervision" },
  Inspection: { path: "/#" },
  ImagesFiles: { path: "/images" },
  SettingProperty: { path: "/datasetting/property" },
  SettingSupervision: { path: "/datasetting/supervision" },
  Imports: { path: "/imports" },
  OnBoarding: { path: "/onboarding" },

  ForgotPassword: { path: `/forgot-password` },
  ResetPassword: { path: `/reset-password/:token` },
  CookiePolicy: { path: `/cookie-policy` },
};
