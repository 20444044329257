import { Button, Form } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const PlanUpload = ({ setStep, setCsvFile, step, setStopStep }) => {
  const { t } = useTranslation();

  const handleChangeFile = (e) => {
    setCsvFile(e);
    // setStep(10);
    setStopStep("PlanTable");
  };

  const handleBack = () => {
    setStopStep(null);
    setStep(4);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {};

  const handleDrop = (e) => {
    e.preventDefault();
    let f = {
      target: {
        files: [e.dataTransfer.files[0]],
      },
    };
    handleChangeFile(f);
  };
  return (
    <>
      <span
        class="material-symbols-outlined step_arrow_back"
        onClick={handleBack}
      >
        arrow_back
      </span>
      <div className="maintenance_main">
        <p className="maintenance_plan_head">
          {t("common.pages.Maintenance plan from file")}
        </p>

        <Form.Control
          type="file"
          name="image"
          id="uploadExcelData"
          onChange={(e) => handleChangeFile(e)}
          style={{ display: "none" }}
          accept=".xlsx,.csv"
          multiple={false}
        />

        <div
          className="csv_uploader_main"
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <label className="csv_uploader" for="uploadExcelData">
            <span class="material-symbols-outlined csv_uploader_icon">
              add_notes
            </span>
            <p className="csv_uploader_head">
              {t("common.pages.Select a CSV or Excel file to import")}
            </p>
            <p className="csv_uploader_head csv_drag_head">
              {t("common.pages.or drag and drop it here")}
            </p>
          </label>
        </div>
      </div>
    </>
  );
};

export default PlanUpload;
