import React from "react";
import "./PricingPlan.css";
import PricingCard from "./pricingCard";
import { useTranslation } from "react-i18next";

const PricingPlan = () => {
  const { t } = useTranslation();

  return (
    <div className="pricing_plan_main">
      <p className="pricing_plan_heading">
        {" "}
        {t("common.pages.Pricing Plans")}{" "}
      </p>
      <div className="stand_and_plus_main">
        <PricingCard type="Standard" />
        <PricingCard type="Standard Plus" />
      </div>
    </div>
  );
};

export default PricingPlan;
