import React from "react";
import Navbar from "./navbar";
import image1 from "../../assets/img/help/img-card-1.svg";
import image2 from "../../assets/img/help/img-card-2.png";
import image3 from "../../assets/img/help/img-card-3.svg";
import { t } from "i18next";
import "./style.css";
import { useHistory } from "react-router-dom";

const Index = () => {
  const history = useHistory();
  return (
    <>
      <Navbar />
      <div className="help-main">
        <div className="header">
          {t("common.pages.Just started using the service? Start here.")}
        </div>
        <div className="cards">
          <div className="card">
            <img
              src={image1}
              alt=""
              className="card-img"
              onClick={() => history.push("/help-resources-articles")}
            />
            <div className="title">
              {t("common.pages.Getting started with Janus")}
            </div>
          </div>
          <div className="card">
            <img
              src={image2}
              alt=""
              className="card-img"
              onClick={() => history.push("/help-resources/video-handler")}
            />
            <div className="title">{t("common.pages.Video tutorials")}</div>
          </div>
          <div className="card">
            <img
              src={image3}
              alt=""
              className="card-img"
              onClick={() => history.push("/help-resources/janus-interface")}
            />
            <div className="title">
              {t("common.pages.Navigating the Janus interface")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
