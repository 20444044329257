import { FilterActivitesYear } from "lib/PlanningLib";
import React, { useEffect, useRef, useState } from "react";
import Loader from "components/common/Loader";
import { Dropdown, Table } from "@themesberg/react-bootstrap";
import "./activitesYear.css";
import api from "api";
import leaf_icon from "../../../../../../assets/img/report/🦆 icon _leaf.png";
import money_icon from "../../../../../../assets/img/report/🦆 icon _money.png";
import risk_icon from "../../../../../../assets/img/report/🦆 icon _risk major.png";
import project_icon from "../../../../../../assets/img/report/🦆 icon _project.png";
import search_icon from "../../../../../../assets/img/report/🦆 icon _search.png";
import constructionImg from "../../../../../../assets/img/construction.png";

import { FaCaretDown } from "react-icons/fa";
import Filter from "components/common/Filter";
import { BsThreeDots } from "react-icons/bs";
import { SidePanelRoot, SidePanelService } from "components/common/SidePanel";
import ActivitesYearSidePanel from "./activitesYearSidePanel";
import { toast } from "react-toastify";
import filesSidePanel from "./filesSidePanel";
import DetailModal from "./detailModal";
import DeleteModal from "./DeleteModal";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import PrintModal from "./PrintModal";
import { Bar, Line } from "react-chartjs-2";
import Analysis from "../../Analysis/Analysis";
import { usePlanningContextCheck } from "context/SidebarContext/PlanningContextCheck";
import { GetAllProperties } from "lib/PropertiesLib";
import {
  contentTexts,
  contentTextsVariables,
  depOptions,
  getAllMaintenanceDiagramData,
  getMaintenanceDepositionData,
  getMaintenanceReport,
  getMaintenanceSettings,
  getUsystems,
  options,
  sortContent,
  uniquePropertyAndBuildings,
} from "utils/MaintenanceReport";
import PrintData from "./PrintData";
import Switch from "../../../../../common/Switch";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import PTSans from "../../../../../../utils/font";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";

const Activitesyear = ({
  printItem,
  createReport,
  handleChangeAction,
  currReprtTab,
}) => {
  const { value } = GetAllProperties(undefined, undefined, true);
  // Filter State
  const [filterValues, setFilterValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const tableRefs = useRef([]);
  const [printModal, setPrintModal] = useState(false);
  const [formData, setFormData] = useState(null);

  // activites year State
  const [maintainancePlan, setMaintainancePlan] = useState([]);
  const [dupMaintainancePlan, setDupMaintainancePlan] = useState([]);
  const [breakIndexs, setBreakIndexs] = useState([]);

  // Delete Modal State
  const [show, setShow] = useState(false);

  // Side Panel State
  const [initalVal, setInitalVal] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);

  // Detail Modal State
  const [detailModal, setDetailModal] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [calculation, setCalculation] = useState(true);

  const [menuCol, setMenuCol] = useState(true);

  const [printData, setPrintData] = useState(null);
  const [dupPrintData, setDupPrintData] = useState(null);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [depositionData, setDepositionData] = useState(null);
  const [uniquePropsAndBuilds, setUniquePropsAndBuilds] = useState(null);
  const [maintenanceReport, setMaintenanceReport] = useState(null);
  const [maintenanceSettings, setMaintenanceSettings] = useState(null);
  const [Usystems, setUsystems] = useState([]);
  const [pageNumbering, setPageNumbering] = useState({});
  const chartRef = useRef(null);
  const lineChartRef = useRef(null);
  const htmlContentRef = useRef(null);

  const [maintananceDiagramData, setMaintananceDiagramData] = useState({
    labels: [],
    datasets: [],
  });
  const { actvsPerTypeBreakIndexs, actvsPerTypePrintData } =
    usePlanningContextCheck();
  const { setActvsPerYearBreakIndexs, setActvsPerYearPrintData } =
    usePlanningContextCheck();
  const printRef = useRef();
  const { t } = useTranslation();

  const depData = {
    labels: depositionData?.depositions?.map((elem) => {
      return elem.deposition_year;
    }),
    datasets: [
      {
        label: t("data_settings.rec_deposition"),
        data: depositionData?.depositions?.map((elem) => {
          return elem.rec_value_fund;
        }),
        backgroundColor: "lightYellow",
        borderColor: "#FF9A25",
        borderWidth: 2,
      },
      {
        label: t("data_settings.current_deposition"),
        data: depositionData?.depositions?.map((elem) => {
          return elem.curr_value_fund;
        }),
        borderColor: "#413F41",
        backgroundColor: "navy",
        borderWidth: 2,
      },
    ],
  };

  // Delete Modal Function
  const deleteModalClose = () => {
    setInitalVal(null);
    setShow(false);
  };

  const handleShow = (item) => {
    setInitalVal(item);
    setShow(true);
  };

  // Detail Modal Functions
  const detailModalClose = () => setDetailModal(false);
  const detailModalShow = (item) => {
    setInitalVal(item);
    setDetailModal(true);
  };

  const handleFindClick = async () => {
    // perform find logic using filterValues

    let filterObj = {};
    for (const key in filterValues) {
      if (filterValues[key]?.length > 0) {
        filterObj[key] = filterValues[key];
      }
    }
    const res = await FilterActivitesYear({
      body: JSON.stringify({
        filters: filterObj,
      }),
    });
    const newData = await res.json();
    setMaintainancePlan(newData);
  };

  const handleSubmit = async (e, data, isCopyItems) => {
    try {
      e.preventDefault();

      if (data?.start_year < 1900 || data?.start_year > 2100) {
        return toast("Start Year Must Be range in 1900 and 2100!", {
          type: "error",
        });
      } else {
        if (isCopyItems) {
          data._id = undefined;
          const res = await api.post(
            "/planning_component/maintainance/activitesPerYear-copy",
            data
          );
        } else {
          let res = await api.patch(
            `/planning_component/maintainance/activitesPerYear-edit/${data._id}`,
            data
          );
          let responseItem = maintainancePlan.map((elem) => {
            if (elem._id == res.data._id) {
              return (elem = res.data);
            } else {
              return elem;
            }
          });
          setMaintainancePlan(responseItem);
        }
        setShowDrawer(false);
      }
    } catch (error) {
      console.log(error);
      toast(error?.response?.data?.message, { type: "error" });
    }
  };

  const handleNewProperty = (item, isCopyItems) => {
    setInitalVal(item);
    setTimeout(() => {
      SidePanelService.open(ActivitesYearSidePanel, {
        handleSubmit,
        initalValue: item,
        isCopyItems,
        handleClose: () => {
          setShowDrawer(false);
        },
      });
    }, 100);
  };

  const filesModal = (item) => {
    setTimeout(() => {
      setInitalVal(item);
      SidePanelService.open(filesSidePanel, {
        handleSubmit,
        initalVal: item,
        handleClose: () => {
          setShowDrawer(false);
        },
      });
    }, 100);
  };

  function splitArray(array, chunkSize, elem) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push({ ...elem, documents: array.slice(i, i + chunkSize) });
    }
    return result;
  }

  // function getUniquePropertyCodes(data) {
  //   const result = {};

  //   data.forEach((item) => {
  //     item.documents.forEach((doc) => {
  //       const { property_code, building_code } = doc;
  //       if (!result[property_code]) {
  //         result[property_code] = new Set();
  //       }
  //       result[property_code].add(building_code);
  //     });
  //   });

  //   return Object.keys(result).map((propertyCode) => ({
  //     propertyCode,
  //     buildingCodes: Array.from(result[propertyCode]),
  //   }));
  // }

  const getAllMaintenancePlan = async (spinner) => {
    if (spinner === false) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    try {
      let allMaintenancePlan = await api.post(
        "/planning_component/maintainance/activitesPerYear"
      );

      let allData = JSON.parse(JSON.stringify(allMaintenancePlan));
      console.log(allMaintenancePlan, "plan");
      let indexs = [];
      let updatedData = [];
      let a = 0;
      let sectionCount = 0;
      for (let i = 0; i < allData?.data?.length; i++) {
        if (a === 9 || sectionCount === 4) {
          updatedData.push(allData?.data[i]);
          indexs.push(i);
          a = allData?.data[i]?.documents?.length;
          sectionCount = 1;
        } else if (a + allData?.data[i]?.documents?.length > 9) {
          let remaining = 9 - a;
          let remElem = allData?.data[i]?.documents.splice(0, remaining);
          let splitArrays;
          if (allData?.data[i]?.documents?.length > 9) {
            splitArrays = splitArray(
              allData?.data[i]?.documents,
              9,
              allData?.data[i]
            );
          } else {
            splitArrays = [allData?.data[i]];
          }
          updatedData.push(
            {
              ...allData?.data[i],
              documents: remElem,
            },
            // allData?.data[i]
            ...splitArrays
          );

          indexs.push(updatedData?.length - 1);
          a = updatedData[updatedData?.length - 1]?.documents?.length;
          sectionCount = 1;
          // indexs.push(i + 1);
          // a = allData?.data[i + 1]?.documents?.length;
          // a = 0;
        } else {
          a += allData?.data[i]?.documents?.length;
          sectionCount += 1;
          updatedData.push(allData?.data[i]);
        }
      }

      // let indexs = [];
      // let a = 0;
      // allMaintenancePlan?.data?.map((el, i) => {
      //   if (a + el?.documents?.length > 9) {
      //     indexs.push(i);
      //     a = 0;
      //   } else {
      //     a += el?.documents?.length;
      //   }
      // });
      setBreakIndexs(indexs);
      setPrintData(allMaintenancePlan?.data);
      setDupPrintData(allMaintenancePlan?.data);
      setMaintainancePlan(allMaintenancePlan.data);
      setDupMaintainancePlan(allMaintenancePlan.data);
      setActvsPerYearBreakIndexs(indexs);
      setActvsPerYearPrintData(allMaintenancePlan?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const calculatePercentage = () => {
    console.log(formData);
    let percent = formData?.general_surcharge;
    if (percent) {
      let updatedPlan = maintainancePlan?.map((elem) => {
        return {
          ...elem,
          totalCost: (elem?.totalCost * percent) / 100 + elem?.totalCost,
          documents: elem.documents.map((item) => {
            if (item?.total_cost) {
              return {
                ...item,
                total_cost:
                  (parseInt(item?.total_cost) * percent) / 100 +
                  parseInt(item?.total_cost),
              };
            } else {
              return item;
            }
          }),
        };
      });
      debugger;
      // let yearlyIncrease = 0;
      console.log(updatedPlan, "before");
      updatedPlan = updatedPlan?.map((elem) => {
        if (
          elem?._id > formData?.base_year_increase &&
          formData?.yearly_increase
        ) {
          // yearlyIncrease = yearlyIncrease + formData?.yearly_increase;
          let total_cost = elem?.totalCost;
          let factor = formData?.yearly_increase / 100 + 1;
          let differenece = elem?._id - formData?.base_year_increase;

          let result = Math.pow(factor, differenece);
          // console.log(elem, "elem");
          // console.log(result, "result");
          return {
            ...elem,
            totalCost: result * total_cost,
            documents: elem.documents.map((item) => {
              if (item?.total_cost) {
                total_cost = item?.total_cost;
                factor = formData?.yearly_increase / 100 + 1;
                differenece = elem?._id - formData?.base_year_increase;
                result = Math.pow(factor, differenece);
                return {
                  ...item,
                  total_cost: result * total_cost,
                };
              } else {
                return item;
              }
            }),
          };
        } else {
          return elem;
        }
      });
      console.log(updatedPlan, "after");

      setMaintainancePlan(updatedPlan);
      setDupMaintainancePlan(updatedPlan);
    }
  };

  useEffect(() => {
    if (formData && maintainancePlan.length > 0 && calculation) {
      calculatePercentage();
      setCalculation(false);
    }
  }, [formData, maintainancePlan]);

  const getMaintenanceReport = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const res = await api.get(`/maintenance-report/${user?._id}`);
      console.log(res, "response");
      setMaintenanceReport(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const getAllMaintenanceDiagramData = async () => {
  //   let a = new Date().getFullYear() + 100;
  //   let b = new Date().getFullYear() - 100;

  //   const user = JSON.parse(localStorage.getItem("user"));
  //   setLoading(true);
  //   try {
  //     let allMaintenancePlan = await api.post(
  //       `/planning_component/maintainance/analysis/${user?._id}`
  //     );
  //     setMaintananceDiagramData({
  //       labels: allMaintenancePlan?.data?.labels,
  //       datasets: allMaintenancePlan?.data?.data,
  //     });
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getMaintenanceDepositionData = async () => {
  //   try {
  //     const userData = JSON.parse(localStorage.getItem("user"));
  //     const res = await api.get(`/maintenance_depositions/${userData._id}`);
  //     setDepositionData(res?.data);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // const getMaintenanceSettings = async () => {
  //   try {
  //     const userData = JSON.parse(localStorage.getItem("user"));
  //     const res = await api.get(`/maintenance_settings/${userData._id}`);
  //     setMaintenanceSettings(res?.data);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // const uniquePropertyAndBuildings = () => {
  //   let uniqueCodes = getUniquePropertyCodes(maintainancePlan || []);
  //   console.log("uniqueCodes", uniqueCodes);

  //   let propsAndBuilds = [];
  //   uniqueCodes?.map((el) => {
  //     let foundP = value?.find((p) => p?.property_code === el?.propertyCode);
  //     propsAndBuilds.push({
  //       property: foundP,
  //       buildingCodes: el?.buildingCodes,
  //     });
  //   });

  //   console.log("propsAndBuilds", propsAndBuilds);
  //   setUniquePropsAndBuilds(propsAndBuilds);
  // };

  const genratePdf = async () => {
    let pdf = new jsPDF({ orientation: "portrait" });

    pdf.addFileToVFS("PTSans-Regular.ttf", PTSans);
    pdf.addFont("PTSans-Regular.ttf", "PTSans", "normal");

    // Add header content (without HTML)
    const header = () => {
      pdf.setFont("PTSans", "normal");
      pdf.setFontSize(12);
      pdf.setTextColor(100);
      pdf.text("Underhållsplan", pdf.internal.pageSize.width - 10, 10, {
        align: "right",
      }); // Header text
      // pdf.setFont("PTSans", "normal");
      pdf.setFont("PTSans", "normal");
      pdf.setFontSize(16);
      pdf.text(user?.organization, pdf.internal.pageSize.width - 10, 18, {
        align: "right",
      }); // Placeholder for organization name
      pdf.setLineWidth(0.1);
      pdf.setDrawColor(124, 124, 124);
      pdf.line(10, 25, pdf.internal.pageSize.width - 10, 25); // Horizontal line for separator
    };

    // Add footer content (without HTML)
    const footer = (pageNumber) => {
      pdf.setFont("PTSans", "normal");
      pdf.setFontSize(11);
      pdf.setTextColor(124, 124, 124);
      pdf.line(
        10,
        pdf.internal.pageSize.height - 17,
        pdf.internal.pageSize.width - 10,
        pdf.internal.pageSize.height - 17
      ); // Horizontal line
      pdf.text(
        "This maintenance plan was created with the web application JANUS.",
        55,
        pdf.internal.pageSize.height - 10
      );
      pdf.text(
        "More details at www.dinunderhallsplan.se",
        68,
        pdf.internal.pageSize.height - 5
      );
    };

    // Generate content for each page
    // const generatePageContent = (pageNum) => {
    //   pdf.setFontSize(12);
    //   autoTable(pdf, {
    //     startY: 30,
    //     head: [["Column 1", "Column 2", "Column 3"]],
    //     body: [
    //       ["Data 1", "Data 2", "Data 3"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A", "Data B", "Data C"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //       ["Data A55", "Data B5", "Data C5"],
    //     ],
    //     didDrawPage: (data) => {
    //       header(); // Call the header on every page
    //       const pageNumber = pdf.internal.getNumberOfPages();
    //       footer(pageNumber); // Call the footer on every page
    //     },
    //   });
    // };

    const addFirstPageContent = () => {
      // Add image
      header();
      footer();
      console.log(maintenanceReport);
      const imageUrl = maintenanceReport?.image || constructionImg;
      const imageWidth = 100; // Width in mm (approximately A4 size width)
      const imageHeight = 100; // Height in mm for scaling
      const marginY = 60; // Top margin
      console.log(imageUrl);
      pdf.setFont("PTSans", "normal");

      pdf.addImage(
        imageUrl,
        "JPEG",
        (pdf.internal.pageSize.width - imageWidth) / 2,
        marginY,
        imageWidth,
        imageHeight
      );

      // Add titles
      pdf.setFontSize(24);
      pdf.setFont("PTSans", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.text(
        "Underhållsplan",
        pdf.internal.pageSize.width / 2,
        marginY + imageHeight + 20,
        { align: "center" }
      );

      pdf.setFontSize(18);
      const startYear =
        filterValues.start_year || maintenanceSettings.plan_start_year;
      const endYear =
        maintenanceSettings.plan_start_year + maintenanceSettings.plan_duration;
      pdf.text(
        `${startYear} - ${endYear}`,
        pdf.internal.pageSize.width / 2,
        marginY + imageHeight + 40,
        { align: "center" }
      );
    };

    const addSecondPageContent = () => {
      // Add title
      header();
      footer();
      pdf.setFontSize(22);
      pdf.setFont("PTSans", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.text("Innehållsförteckning", pdf.internal.pageSize.width / 2, 40, {
        align: "center",
      });

      // Add dynamic content from selectedPoints
      pdf.setFontSize(15);
      pdf.setFont("PTSans", "normal");
      let yOffset = 60;
      selectedPoints.forEach((el) => {
        if (contentTexts.includes(el)) {
          pdf.text(contentTextsVariables[el], 20, yOffset);
          pdf.text(
            `${pageNumbering[el]}`,
            pdf.internal.pageSize.width - 40,
            yOffset
          ); // Right align page number
          yOffset += 10;
        }
      });
    };

    const addThirdPageContent = () => {
      // Applied Filters
      header();
      footer();

      if (Object.keys(filterValues).length > 0) {
        pdf.setFontSize(22);
        pdf.setFont("PTSans", "bold");
        pdf.text("Applied Filters", 20, 40);

        let yOffset = 60;
        Object.keys(filterValues).forEach((key) => {
          pdf.setFontSize(14);
          pdf.setFont("PTSans", "normal");
          pdf.text(key, 20, yOffset);
          pdf.text(filterValues[key].join(", "), 100, yOffset);
          yOffset += 10;
        });
      }

      // Plan Settings
      pdf.setFontSize(22);
      pdf.setFont("PTSans", "normal");
      pdf.setTextColor(0, 0, 0);
      let text = `Plan ${t("property_page.Settings")}`;
      pdf.text(text, pdf.internal.pageSize.width / 2, 40, {
        align: "center",
      });

      const settings = [
        { label: "Namn", value: maintenanceSettings?.version_name },
        { label: "Startår", value: maintenanceSettings?.plan_start_year },
        { label: "Längd", value: maintenanceSettings?.plan_duration },
        {
          label: "Generellt påslag",
          value: maintenanceSettings?.general_surcharge,
        },
        { label: "Moms", value: maintenanceSettings?.vat_percent },
        {
          label: "Årlig uppräkning",
          value: maintenanceSettings?.yearly_increase,
        },
        {
          label: "Basår index",
          value: maintenanceSettings?.base_year_increase,
        },
      ];

      let settingsOffset = Object.keys(filterValues).length > 0 ? 100 : 80;
      settings.forEach((setting, index) => {
        pdf.setFontSize(14);
        pdf.setFont("PTSans", "normal");
        pdf.setFillColor(200, 200, 255); // Light blue background
        pdf.rect(18, settingsOffset - 6, 70, 10, "F"); // '
        pdf.text(setting.label, 20, settingsOffset);
        pdf.text(`${setting.value}`, 100, settingsOffset);
        settingsOffset += 10;
      });
    };

    const addFourthPageContent = () => {
      header();
      pdf.setFontSize(18);
      pdf.setFont("PTSans", "normal");
      pdf.text("Property and Building Data", 105, 34, { align: "center" });

      pdf.autoTable({
        startY: 40,
        head: [["Legal Name", "Address", "Area Boa", "Buildings"]],
        body:
          filterValues?.properties && filterValues?.properties.length > 0
            ? filterValues?.properties.map((el) => {
                let pfound = value?.find((p) => p?.name === el);
                return [
                  pfound?.legal_name || "",
                  pfound?.street_address || "",
                  pfound?.sum_area_boa || "",
                  pfound?.buildingCodes?.join(", ") || "",
                ];
              })
            : value?.map((el) => [
                el?.legal_name || "",
                el?.street_address || "",
                el?.sum_area_boa || "",
                el?.buildingCodes?.join(", ") || "",
              ]),
        styles: {
          fillColor: "white",
          textColor: "black",
          fontFamily: "sans-serif",
        },
      });

      footer(4); // Add footer on page 4
    };

    const addFifthPageContent = () => {
      let pageHeight = pdf.internal.pageSize.height; // Height of the page
      let marginBottom = 20; // Margin from the bottom of the page to trigger a new page
      let currentY = 35;

      pdf.setFontSize(18);
      pdf.setFont("sans-serif", "normal");

      console.log(printData, "printData");

      printData?.forEach((elem, index) => {
        let currentIndex = index;
        // elem.doucments = [
        //   {
        //     maintenance_activity: "2027",
        //     article: null,
        //     u_system: null,
        //     technical_life: null,
        //     energy_flag: null,
        //     status: "EX. VAT",
        //     total_cost: "875 500",
        //   },
        //   ...elem?.documents,
        // ];
        // Add the parent header for the year (like 2024, 2025, etc.)

        let yearY;
        // if (pdf?.previousAutoTable.finalY > 270) {
        //   pdf.addPage();
        //   header();
        //   footer();
        //   yearY = 35;
        // } else {
        yearY = index > 0 ? pdf?.previousAutoTable.finalY + 5 : 30;
        // }

        let year = `${elem?._id}`;
        let status = switchState
          ? t("common.pages.INC. VAT")
          : t("common.pages.EX. VAT");
        let total_cost = `${elem.totalCost
          ?.toLocaleString()
          .replace(/,/g, " ")}`;
        pdf.setFontSize(11);
        pdf.setFont("sans-serif", "normal");
        pdf.setFillColor("#FFFF88");
        // pdf.text(year, 24, yearY, {
        //   align: "center",
        // });
        // pdf.text(status, 160, yearY, {
        //   align: "center",
        // });
        // pdf.text(total_cost, 185, yearY, {
        //   align: "center",
        // });

        // Adjust for the next element's Y position

        // Render the detailed activity table for each year
        console.log(pdf.previousAutoTable);
        let totalArr = [
          {
            maintenance_activity: year,
            article: "",
            u_system: "",
            technical_life: "",
            energy_flag: "",
            status: status,
            total_cost: total_cost,
          },
        ];
        pdf.autoTable({
          startY: index > 0 ? pdf.previousAutoTable.finalY + 5 : yearY,
          head: totalArr?.map((item) => [
            item.maintenance_activity,
            item.article,
            item.u_system,
            item.technical_life,
            (item?.energy_flag ? "Energy, " : "") +
              (item?.invest_flag ? "Investment, " : "") +
              (item?.risk_flag ? "Risk, " : "") +
              (item?.project_flag ? "Project, " : "") +
              (item?.inspection_flag ? "Inspection" : ""),
            item.status || "Choose",
            item?.total_cost?.toLocaleString().replace(/,/g, " "),
          ]),
          body: [],
          columnStyles: {
            0: { cellWidth: 20 }, // First column (Activity) has width of 30
            1: { cellWidth: 0 }, // Second column (Article) auto-sizes
            2: { cellWidth: 0 }, // Third column (System) has width of 40
            3: { cellWidth: 0 }, // Fourth column (Interval) has width of 30
            4: { cellWidth: 20 }, // Fifth column (Flags) has width of 20
            5: { cellWidth: 90 }, // Sixth column (Status) has width of 25
            6: { cellWidth: 90 }, // Seventh column (Total Cost) auto-sizes
          },
          styles: {
            fontFamily: "sans-serif",
            fillColor: "#C0C0C0",
            textColor: "black",
          },
        });
        pdf.autoTable({
          startY: pdf.previousAutoTable.finalY + 4,
          head: [
            [
              "Activity",
              "Article",
              "System",
              "Interval",
              "Flags",
              "Status",
              "Total Cost",
            ],
          ],
          body: elem?.documents?.map((item) => [
            item.maintenance_activity,
            item.article,
            item.u_system,
            item.technical_life + " years",
            (item.energy_flag ? "Energy, " : "") +
              (item.invest_flag ? "Investment, " : "") +
              (item.risk_flag ? "Risk, " : "") +
              (item.project_flag ? "Project, " : "") +
              (item.inspection_flag ? "Inspection" : ""),
            item.status || "Choose",
            item?.total_cost?.toLocaleString().replace(/,/g, " "),
          ]),
          styles: {
            fontFamily: "sans-serif",
            fillColor: "white",
            textColor: "black",
          },

          didDrawPage: (data) => {
            header();
            footer();
            // pdf.previousAutoTable.finalY = 35;
            const tableBottomY = pdf.previousAutoTable.finalY;
            // currentY = tableBottomY + 5;
            // // Check if the table is about to exceed the page height, and trigger a new page
            console.log(tableBottomY, pageHeight, marginBottom);
            if (
              tableBottomY >= pageHeight - marginBottom &&
              data?.row?.index === 0
            ) {
              currentIndex = 1;
              // currentY = pdf.previousAutoTable.finalY + 20;
              // pdf.addPage();
              yearY = 35;
            }
          },
        });
      });

      // Footer on page 5
    };

    const renderSixthPagePDF = () => {
      pdf.setFont("sans-serif", "normal");

      const columns = [
        { header: t("planning_page.activity"), dataKey: "activity" },
        { header: t("planning_page.article"), dataKey: "article" },
        { header: "SYSTEM", dataKey: "system" },
        { header: t("planning_page.interval"), dataKey: "interval" },
        { header: t("planning_page.flags"), dataKey: "flags" },
        { header: "STATUS", dataKey: "status" },
        { header: t("planning_page.total_cost"), dataKey: "totalCost" },
      ];

      // Add a new page (6th page)

      actvsPerTypePrintData.forEach((elem, index) => {
        let yearY = index > 0 ? pdf?.previousAutoTable.finalY + 5 : 30;
        const tableData = elem?.documents?.map((item) => {
          return {
            activity:
              item.maintenance_activity.length <= 73
                ? item.maintenance_activity
                : `${item.maintenance_activity.substring(0, 50)}...`,
            article: item.article,
            system: item.start_year,
            interval: `${item.technical_life} ${t("planning_page.years")}`,
            flags: getFlagsIcons(item), // helper function to get flag icons
            status: getStatusLabel(item, t), // helper function to get status label
            totalCost: formatCost(item.total_cost),
          };
        });

        // Insert table header
        pdf.autoTable({
          head: [
            [
              `${elem._id} ${elem.uSystemName}`,
              "",
              "",
              "",
              "",
              "",
              `${formatCost(elem.totalCost)}`,
            ],
          ],
          theme: "plain",
          startY: index > 0 ? pdf.previousAutoTable.finalY + 5 : yearY,
          styles: {
            fontFamily: "sans-serif",
            fillColor: "#C0C0C0",
            textColor: "black",
          },
        });

        // Render table body
        pdf.autoTable({
          head: [columns.map((col) => col.header)],
          body: tableData.map((row) => columns.map((col) => row[col.dataKey])),
          startY: pdf.previousAutoTable.finalY + 4,
          margin: { top: 20 },
          columnStyles: { 0: { cellWidth: 50 }, 1: { cellWidth: 25 } },
          styles: {
            fontFamily: "sans-serif",
            fillColor: "white",
            textColor: "black",
          },
          didDrawPage: (data) => {
            // Optional: Add page numbers or footer here
            header();
            footer();
          },
        });
      });
    };

    function getFlagsIcons(item) {
      let flags = "";
      if (item.energy_flag) flags += "🌿"; // Energy Flag Icon
      if (item.invest_flag) flags += "💰"; // Invest Flag Icon
      if (item.risk_flag) flags += "⚠️"; // Risk Flag Icon
      if (item.project_flag) flags += "📋"; // Project Flag Icon
      if (item.inspection_flag) flags += "🔍"; // Inspection Flag Icon
      return flags;
    }

    function getStatusLabel(item, t) {
      switch (item.status) {
        case "Planerad":
          return t("plan_color");
        case "Akut":
          return t("akut_color");
        case "Eftersatt":
          return t("efter_color");
        case "Beslutad":
          return t("beslu_color");
        case "Utförd":
          return t("utford_color");
        default:
          return t("common.pages.choose");
      }
    }

    function formatCost(cost) {
      return cost?.toLocaleString()?.replace(/,/g, " ");
    }

    if (selectedPoints.includes("coverPage")) {
      addFirstPageContent();
    }
    if (selectedPoints.includes("tableOfContent")) {
      pdf.addPage();
      addSecondPageContent();
    }
    if (selectedPoints.includes("planSettings")) {
      pdf.addPage();
      addThirdPageContent();
    }
    if (selectedPoints.includes("propertyAndBuildingData")) {
      pdf.addPage();
      addFourthPageContent();
    }
    if (selectedPoints.includes("maintenanceActivitiesPerYear")) {
      pdf.addPage();
      addFifthPageContent();
    }
    if (selectedPoints.includes("maintenanceActivitiesPerSystem")) {
      pdf.addPage();
      renderSixthPagePDF();
    }
    if (
      selectedPoints.includes("maintenanceDiagram") ||
      selectedPoints.includes("depositionsDiagram") ||
      selectedPoints.includes("myCustomText")
    ) {
      let text;
      let chartElement = chartRef.current;

      if (selectedPoints.includes("myCustomText")) {
        pdf.addPage();
        pdf.setPage(2);
        // pdf.addPage();
        // await new Promise((resolve) => setTimeout(resolve, 5000));
        // console.log("promise");

        let startPage = pdf.internal.getCurrentPageInfo().pageNumber;
        header(); // Add header on the current page
        footer();

        await pdf.html(htmlContentRef.current, {
          callback: () => {
            const endPage = pdf.internal.getCurrentPageInfo().pageNumber; // Get the last page created by the content
            // pdf.setPage(3);

            // Add headers and footers only to the pages affected by the HTML content
            console.log(startPage, endPage);
            // pdf.addPage();
            // pdf.setPage(2);

            if (
              !selectedPoints.includes("depositionsDiagram") &&
              !selectedPoints.includes("maintenanceDiagram")
            ) {
              pdf.save("large-content.pdf");
            }
          },
          x: 7,
          y: 10,
          width: 190, // Adjust width as per your content
          windowWidth: 800,

          // Set the width for the rendering context
          margin: [10, 7, 15, 10], // Optional margin
          autoPaging: true, // Ensure content goes to the next page
        });
        // pdf.addPage();
      }

      if (selectedPoints.includes("maintenanceDiagram")) {
        pdf.addPage();

        chartElement.style.visibility = "visible";
        chartElement.style.position = "absolute"; // Keep it out of flow
        chartElement.style.zIndex = "-1"; // Send it to the back

        let canvas = await html2canvas(chartElement);
        let maintainenceImageData = canvas.toDataURL("image/png");
        let imgWidth = 190; // PDF page width minus margins
        let imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.setFontSize(16);
        text = t("property_page.Maintenance_diagram");
        pdf.text(text, 105, 34, { align: "center" });
        pdf.addImage(maintainenceImageData, "PNG", 10, 50, imgWidth, imgHeight);
        if (!selectedPoints.includes("depositionsDiagram")) {
          chartElement.style.visibility = "hidden";
          pdf.save("report.pdf");
        }
      }

      if (selectedPoints.includes("depositionsDiagram")) {
        pdf.addPage();
        let lineChartElement = lineChartRef.current;
        lineChartElement.style.visibility = "visible";
        lineChartElement.style.position = "absolute"; // Keep it out of flow
        lineChartElement.style.zIndex = "-1";

        let canvas2 = await html2canvas(lineChartElement);
        const imgData2 = canvas2.toDataURL("image/png");

        const imgWidth2 = 190; // PDF page width minus margins
        const imgHeight2 = (canvas2.height * imgWidth2) / canvas2.width; // Maintain aspect ratio

        pdf.setFontSize(16);
        text = t("property_page.Maintenance_diagram");
        pdf.text(text, 105, 34, { align: "center" });
        pdf.addImage(imgData2, "PNG", 10, 50, imgWidth2, imgHeight2);
        // if (!selectedPoints.includes("myCustomText")) {
        pdf.save("chart.pdf"); // Save PDF here after chart is added
        lineChartElement.style.visibility = "hidden";
        chartElement.style.visibility = "hidden";
        // }
      }
    }

    if (
      selectedPoints.includes("maintenanceDiagram") ||
      selectedPoints.includes("depositionsDiagram") ||
      selectedPoints.includes("myCustomText")
    ) {
    } else {
      pdf.save("download");
    }
  };

  const handlePrintClick = () => {
    setMenuCol(false);
    setTimeout(() => {
      genratePdf();
      handleChangeAction(null);
    }, [100]);
  };

  const handleOpenPrintModal = () => {
    setPrintModal(true);
  };

  const handleClosePrintModal = () => {
    setSelectedPoints([]);
    handleChangeAction(null);
    setPrintModal(false);
  };

  useEffect(() => {
    let u = JSON.parse(localStorage.getItem("user"));
    setUser(u);
    getAllMaintenancePlan();
  }, [showDrawer]);

  useEffect(() => {
    let u = JSON.parse(localStorage.getItem("user"));
    if (u) getMaintencanceSettings(u?._id);
    getAllMaintenanceDiagramData(setMaintananceDiagramData);
    getMaintenanceDepositionData(setDepositionData);
    getMaintenanceReport(setMaintenanceReport);
    getMaintenanceSettings(setMaintenanceSettings);
    getUsystems(setUsystems);
  }, []);

  useEffect(() => {
    if (printItem && currReprtTab === "activitesyear") {
      handleChangePoint("maintenanceActivitiesPerYear");
      handlePrintClick();
    }
  }, [printItem]);

  useEffect(() => {
    if (createReport && currReprtTab === "activitesyear") {
      handleOpenPrintModal();
    }
  }, [createReport]);

  // useEffect(() => {
  //   uniquePropertyAndBuildings(
  //     maintainancePlan,
  //     value,
  //     setUniquePropsAndBuilds
  //   );
  // }, [value, maintainancePlan]);

  const handleChangeStatus = async (StatusName, documentId) => {
    try {
      let res = await api.patch(
        `/planning_component/maintainance/activites-year-status/${documentId}`,
        { StatusName }
      );
      handleFindClick();
    } catch (error) {
      console.log(error);
    }
  };

  const getMaintencanceSettings = async (id) => {
    const res = await api.get(`/maintenance_settings/${id}`);
    setFormData(res?.data);
  };

  // const genratePdf = useReactToPrint({
  //   content: () => printRef.current,
  //   documentTitle: "Activities Per Year",
  //   onAfterPrint: () => {
  //     setMenuCol(true);
  //   },
  // });

  const getStatusColor = (status) => {
    status = status?.charAt(0).toUpperCase() + status?.slice(1).toLowerCase();

    const statusMap = {
      Planerad: "plan_color",
      Akut: "akut_color",
      Eftersatt: "efter_color",
      Beslutad: "beslu_color",
      Utförd: "utford_color",
    };

    const statusClass = statusMap[status];

    return statusClass ? (
      <div className={`plan_color_div dropdown_icon ${statusClass}`}></div>
    ) : null;
  };

  const handleChangePoint = (point) => {
    if (selectedPoints?.includes(point)) {
      setSelectedPoints(selectedPoints?.filter((el) => el !== point));
    } else {
      setSelectedPoints([...selectedPoints, point]);
    }
  };

  // const sortContent = () => {
  //   // Create a lookup map for the order values
  //   const orderMap = {};
  //   contentOrder.forEach((item) => {
  //     const key = Object.keys(item)[0];
  //     orderMap[key] = item[key];
  //   });
  //   // Sort the array based on the lookup map
  //   // selectedPoints.sort((a, b) => orderMap[a] - orderMap[b]);
  //   // console.log("breakIndexs", breakIndexs);
  //   // console.log("actvsPerTypeBreakIndexs", actvsPerTypeBreakIndexs);

  //   const defaultOrderValue = 100;

  //   // Sort the array based on the lookup map
  //   selectedPoints.sort((a, b) => {
  //     const orderA =
  //       orderMap[a] !== undefined ? orderMap[a] : defaultOrderValue;
  //     const orderB =
  //       orderMap[b] !== undefined ? orderMap[b] : defaultOrderValue;
  //     return orderA - orderB;
  //   });

  //   console.log(selectedPoints);

  //   let lastNo = 0;
  //   let pageNumbers = {};
  //   selectedPoints?.map((el) => {
  //     if (el === "coverPage") {
  //       lastNo += 1;
  //     }
  //     if (el === "tableOfContent") {
  //       lastNo += 1;
  //     }
  //     if (el === "planSettings") {
  //       lastNo += 1;
  //       pageNumbers.planSettings = lastNo;
  //     }
  //     if (el === "myCustomText") {
  //       lastNo += 1;
  //       pageNumbers.myCustomText = lastNo;
  //     }
  //     if (el === "propertyAndBuildingData") {
  //       pageNumbers.propertyAndBuildingData = lastNo + 1;
  //       lastNo += Math.round(uniquePropsAndBuilds?.length / 2);
  //     }
  //     if (el === "maintenanceDiagram") {
  //       lastNo += 1;
  //       pageNumbers.maintenanceDiagram = lastNo;
  //     }
  //     if (el === "maintenanceActivitiesPerYear") {
  //       pageNumbers.maintenanceActivitiesPerYear = lastNo + 1;
  //       lastNo += breakIndexs == 0 ? 1 : breakIndexs?.length + 2;
  //     }
  //     if (el === "maintenanceActivitiesPerSystem") {
  //       pageNumbers.maintenanceActivitiesPerSystem = lastNo + 1;
  //       lastNo +=
  //         actvsPerTypeBreakIndexs == 0
  //           ? 1
  //           : actvsPerTypeBreakIndexs?.length + 2;
  //     }
  //     if (el === "depositionsDiagram") {
  //       pageNumbers.depositionsDiagram = lastNo + 1;
  //       // lastNo +=
  //       //   actvsPerTypeBreakIndexs == 0
  //       //     ? 1
  //       //     : actvsPerTypeBreakIndexs?.length + 2;
  //     }
  //   });
  //   setPageNumbering(pageNumbers);
  // };

  const addVAT = (data, percent) => {
    let updatedPlan = data?.map((elem) => {
      return {
        ...elem,
        totalCost: (elem?.totalCost * percent) / 100 + elem?.totalCost,
        documents: elem.documents.map((item) => {
          if (item?.total_cost) {
            return {
              ...item,
              total_cost:
                (parseInt(item?.total_cost) * percent) / 100 +
                parseInt(item?.total_cost),
            };
          } else {
            return item;
          }
        }),
      };
    });
    return updatedPlan;
  };

  useEffect(() => {
    sortContent(
      selectedPoints,
      uniquePropsAndBuilds,
      breakIndexs,
      actvsPerTypeBreakIndexs,
      setPageNumbering
    );
  }, [selectedPoints]);

  const handleChangeSwitch = (e) => {
    setSwitchState(e);
    console.log(printData);
    debugger;
    if (e === true && formData?.vat_percent) {
      let percent = formData.vat_percent;
      let updatedPlan = addVAT(maintainancePlan, percent);
      let updatedPrintData = addVAT(printData, percent);
      setMaintainancePlan(updatedPlan);
      setPrintData(updatedPrintData);
      setActvsPerYearPrintData(updatedPrintData);
    } else {
      setMaintainancePlan(dupMaintainancePlan);
      setPrintData(dupPrintData);
      setActvsPerYearPrintData(dupPrintData);
    }
  };

  return (
    <>
      {/* Filter Code */}
      <Filter
        handleFindClick={handleFindClick}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        status={true}
      />
      <div
        ref={htmlContentRef}
        style={{ padding: 20, pageBreakBefore: "always", display: "none" }}
        dangerouslySetInnerHTML={{ __html: maintenanceReport?.value }}
      />
      <div
        className="maintancne_diagram"
        ref={chartRef}
        style={{ visibility: "hidden", position: "absolute", zIndex: "-1" }}
        // style={{ display: "none" }}
      >
        <Bar data={maintananceDiagramData} options={options} />
      </div>
      <div>
        <div
          className="maintancne_diagram"
          ref={lineChartRef}
          style={{ visibility: "hidden", position: "absolute", zIndex: "-1" }}
        >
          <Line data={depData} options={depOptions} />
        </div>
      </div>
      <div className="side_paneL_root_main">
        {showDrawer && <SidePanelRoot />}
      </div>
      {loading ? (
        <div style={{ marginBottom: "1rem" }}>
          <Loader />
        </div>
      ) : (
        <div
          // ref={printRef}
          // style={{ width: "100%" }}
          className="table_scroll"
        >
          <Table>
            <thead>
              <tr className="activites_header">
                <th>{t("planning_page.activity")}</th>
                <th>{t("planning_page.article")}</th>
                <th>SYSTEM</th>
                <th>{t("planning_page.interval")}</th>
                <th>{t("planning_page.flags")}</th>
                <th>STATUS</th>
                <th>
                  <div className="vat_switch">
                    <Switch
                      checked={switchState}
                      setChecked={setSwitchState}
                      onChange={handleChangeSwitch}
                      text={
                        !switchState
                          ? t("common.pages.EX. VAT")
                          : t("common.pages.INC. VAT")
                      }
                    />
                  </div>
                  <div>{t("planning_page.total_cost")}</div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="activites_year_table_main">
              {maintainancePlan?.map((elem) => (
                <>
                  <tr className="activites_start_year activites_year_cost_main">
                    <td>{elem._id}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {`${elem.totalCost}`?.length <= 4
                        ? elem.totalCost
                        : elem.totalCost.toLocaleString()}
                    </td>
                    <td></td>
                  </tr>
                  {elem?.documents?.map((item) => (
                    <>
                      <tr className={"activites_start_year activites_activity"}>
                        <td className="reportYearTD activiCol">
                          <div style={{ textWrap: "wrap" }}>
                            {item.maintenance_activity?.length <= 73
                              ? item.maintenance_activity
                              : `${item.maintenance_activity.substring(
                                  0,
                                  73
                                )}...`}
                            {/* {item.maintenance_activity} */}
                          </div>
                        </td>
                        <td className={"reportYearTD"}>{item?.article}</td>
                        <td className={"reportYearTD"}>{item.u_system}</td>
                        <td className={"reportYearTD"}>
                          {item.technical_life
                            ? item.technical_life +
                              " " +
                              t("planning_page.years")
                            : "-"}
                        </td>
                        <td className={"reportYearTD"}>
                          {item.energy_flag && (
                            <img
                              src={leaf_icon}
                              alt="leaf-icon"
                              className={"leaf_img"}
                            />
                          )}
                          {item.invest_flag && (
                            <img
                              src={money_icon}
                              alt="money-icon"
                              className={"leaf_img"}
                            />
                          )}
                          {item.risk_flag && (
                            <img
                              src={risk_icon}
                              alt="risk-icon"
                              className={"leaf_img"}
                            />
                          )}
                          {item.project_flag && (
                            <img
                              src={project_icon}
                              alt="project-icon"
                              className={"leaf_img"}
                            />
                          )}

                          {item.inspection_flag && (
                            <img
                              src={search_icon}
                              alt="search-icon"
                              className={"leaf_img"}
                            />
                          )}
                        </td>
                        <td className={"reportYearTD"}>
                          <Dropdown className={"dropdown_year"}>
                            <Dropdown.Toggle className="activites_year_dropdown activtesYear_dropdown_btn activites_dropdown">
                              <div className="status_color_main">
                                {getStatusColor(item?.status)}
                                {!item.status || item.status === "Choose"
                                  ? t("common.pages.choose")
                                  : item?.status?.charAt(0).toUpperCase() +
                                    item?.status?.slice(1).toLowerCase()}
                              </div>
                              <FaCaretDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Planerad", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon plan_color"></div>
                                Planerad
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Akut", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon akut_color"></div>
                                Akut
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Eftersatt", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon efter_color"></div>
                                Eftersatt
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Beslutad", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon beslu_color"></div>
                                Beslutad
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Utförd", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon utford_color"></div>
                                Utförd
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td className={"reportYearTD"}>
                          {/* {`${item.total_cost}`?.length <= 4
                            ? item.total_cost
                            : item.total_cost
                                ?.toLocaleString()
                                ?.replace(/,/g, " ")} */}
                          {item.total_cost?.toLocaleString()}
                        </td>
                        <td>
                          <Dropdown className="dropdown_year" drop={"left"}>
                            <Dropdown.Toggle className="activites_year_dropdown">
                              <BsThreeDots />
                            </Dropdown.Toggle>
                            <div className="dropdown_menu_main">
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => detailModalShow(item)}
                                  className="Year_edit_menu_item"
                                >
                                  {t("planning_page.details")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setShowDrawer(true);
                                    filesModal(item);
                                  }}
                                  className="Year_edit_menu_item"
                                >
                                  {t("planning_page.files")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setShowDrawer(true);
                                    handleNewProperty(item, true);
                                  }}
                                  className="Year_edit_menu_item"
                                >
                                  {t("planning_page.copy")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="Year_edit_menu_item"
                                  onClick={() => {
                                    setShowDrawer(true);
                                    handleNewProperty(item, false);
                                  }}
                                >
                                  {t("planning_page.edit")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="Year_edit_menu_item"
                                  onClick={() => handleShow(item)}
                                >
                                  {t("planning_page.delete")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </div>
                          </Dropdown>
                        </td>
                      </tr>
                    </>
                  ))}
                </>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {/* ... Tables For Print ... */}
      <div
        ref={printRef}
        // className={menuCol ? "displayNone" : ""}
        style={{ margin: "0px 45px 0px 45px" }}
      >
        <PrintData
          selectedPoints={selectedPoints}
          maintenanceReport={maintenanceReport}
          pageNumbering={pageNumbering}
          filterValues={filterValues}
          maintenanceSettings={maintenanceSettings}
          uniquePropsAndBuilds={uniquePropsAndBuilds}
          maintananceDiagramData={maintananceDiagramData}
          options={options}
          user={user}
          printData={printData}
          breakIndexs={breakIndexs}
          actvsPerTypeBreakIndexs={actvsPerTypeBreakIndexs}
          actvsPerTypePrintData={actvsPerTypePrintData}
          depositionData={depositionData}
          depOptions={depOptions}
          Usystems={Usystems}
          allProperties={value}
          switchState={switchState}
        />
      </div>
      {/* Delete Modal */}
      {show && (
        <DeleteModal
          deleteModalClose={deleteModalClose}
          show={show}
          initalVal={initalVal}
          setMaintainancePlan={setMaintainancePlan}
          maintainancePlan={maintainancePlan}
        />
      )}
      {/* Detail Modal */}
      {detailModal && (
        <DetailModal
          detailModalClose={detailModalClose}
          detailModal={detailModal}
          initalVal={initalVal}
        />
      )}
      {/* Print Modal */}
      <PrintModal
        show={printModal}
        setPrintModal={setPrintModal}
        handleChangeAction={handleChangeAction}
        handleChangePoint={handleChangePoint}
        handlePrintClick={handlePrintClick}
        setSelectedPoints={setSelectedPoints}
        handleClosePrintModal={handleClosePrintModal}
      />
    </>
  );
};

export default Activitesyear;
