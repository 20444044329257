import React from "react";
import { Modal, Button } from "@themesberg/react-bootstrap";
import api from "api";
import { useTranslation } from "react-i18next";

const DeleteModal = ({
  deleteModalClose,
  show,
  modalBody,
  modalHeader,
  deleteFunction,
  deleteItemId,
  // ..............
  initalVal,
  maintainancePlan,
  setMaintainancePlan,
  activitesType,
  maintenanceCreate,
  propertyModal,
  setPopulateData,
  // DataSettingItemIntialVal,
  // DataSettingPkgIntialVal,
  // deleteMaintenancePackage,
  // deleteComponent,
  // DataSettingCompIntialVal,
  // deleteComponentPackage,
  // DataSettingCompPkgIntialVal,
}) => {
  const { t } = useTranslation();
  const maintenanceItemDelete = async () => {
    try {
      let res = await api.delete(
        `/planning_component/maintainance/activitesPerYear-delete/${initalVal?._id}?activitesType=${activitesType}`
      );

      let filterData = [];
      if (res?.data?.length > 0) {
        filterData = maintainancePlan?.map((el) => {
          if (el?._id === res?.data?.[0]?._id) {
            return res?.data[0];
          } else {
            return el;
          }
        });
      } else {
        filterData = maintainancePlan?.filter(
          (el) =>
            el?._id !==
            (activitesType === "activitesType"
              ? initalVal?.u_system
              : initalVal?.start_year)
        );
      }
      setMaintainancePlan(filterData);
      deleteModalClose();
    } catch (error) {
      console.log(error);
    }
  };

  const evaluate = () => {
    if (propertyModal) {
      setPopulateData(true);
    } else if (modalBody) {
      deleteFunction(deleteItemId);
    } else {
      maintenanceItemDelete();
    }
  };

  return (
    <Modal show={show} onHide={deleteModalClose} animation={false} centered>
      <Modal.Header className="modal_delete_maintenanceItem_heading">
        <Modal.Title className="modaldelete_title">
          {maintenanceCreate
            ? "Select a building"
            : propertyModal
            ? "Change Component Detail"
            : modalHeader
            ? modalHeader
            : t("planning_page.Delete_Maintenance_Item")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {maintenanceCreate
          ? "There is no active building,please select one and try again"
          : propertyModal
          ? "Do you also want to change intervals and texts"
          : modalBody
          ? modalBody
          : t("planning_page.are_you_sure_you_delete_this_maintenance")}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => evaluate()}
          className={
            maintenanceCreate
              ? "deletemodal_close_button modal_delete_maintenanceItem_heading createEditModel"
              : "deletemodal_close_button modal_delete_maintenanceItem_heading"
          }
        >
          {propertyModal ? "Yes" : t("planning_page.delete")}
        </Button>
        <Button
          variant="primary"
          onClick={deleteModalClose}
          className="deletemodal_close_button"
        >
          {propertyModal ? "No" : t("planning_page.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
