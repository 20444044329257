import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { CSSTransition } from "react-transition-group";
import {
  Nav,
  Image,
  Button,
  Dropdown,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import Logo from "../../assets/img/janus.png";
import ReactHero from "../../assets/img/technologies/react-hero-logo.svg";
import { useTranslation } from "react-i18next";
import CreateNewDrawer from "./CreateNewDrawer";

const ImagesFilesSidebar = (props = {}) => {
  const { sidebarShow, NavItem } = props;
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  const showClass = show ? "show" : "";
  const onCollapse = () => setShow(!show);

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className={`${
          sidebarShow ? "navbar-theme-primary px-4 d-md-none" : "d-none"
        }`}
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`${
            sidebarShow
              ? `collapse ${showClass} sidebar d-md-block bg-primary text-white`
              : "hidden"
          }`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <span class="material-symbols-outlined">close</span>
              </Nav.Link>
            </div>
            {/* <Dropdown
              className="d-flex m-auto my-3"
              style={{ width: "fit-content" }}
            >
              <Dropdown.Toggle
                as={Button}
                style={{
                  padding: "7px 28px",
                  borderRadius: "33px",
                  fontSize: "14px",
                  margin: "auto",
                }}
                variant="secondary"
                className="text-light me-2 d-flex align-items-center"
              >
                <span class="material-symbols-outlined me-2">add</span>
                <span className="">{t("common.sidebar.create_new")}</span>
              </Dropdown.Toggle>
            </Dropdown> */}
            <Nav className="flex-column pt-3 pt-md-4">
              <Link
                className="d-flex align-items-center justify-content-center mb-5 cursor-pointer"
                style={{ cursor: "pointer" }}
                to="/app"
              >
                <Image src={Logo} width={50} height={50} />
                {/* <p style={{ marginLeft: "7px" }} className="my-auto">
                  Janus
                </p> */}
              </Link>
              {/* CreateNewDrawer */}
              <div className="create_new_main">
                <CreateNewDrawer />
              </div>
              <div className="property_image_main" style={{ width: "8.2vw" }}>
                <span class="material-symbols-outlined">image</span>
                {t("common.pages.Images & Files")}
                {/* {t("common.sidebar.data_settings")} */}
              </div>
              <NavItem
                title={t("common.sidebar.go_back")}
                link={Routes.DashboardOverview.path}
                icon={"arrow_back"}
              />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default ImagesFilesSidebar;
